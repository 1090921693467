import React, { FC } from "react";
import "./Button.scss";
import Spinner from "../Spinner/Spinner";
import { GlobeAmericas } from "react-bootstrap-icons";
import Dropdown from "react-bootstrap/Dropdown";
import { formatCurrency } from "../../../services/currency";
import { IPrice, PriceData2 } from "../../../api/backlinks";

interface ButtonProps {
  text: string;
  loading?: boolean;
  onClick?: any; // Updated the onClick prop type
  extraClassName?: string;
  isDisabled?: boolean;
  price?: IPrice;
  casinoPrice?: PriceData2;
}

export const ButtonGradient: FC<ButtonProps> = ({
  text,
  loading,
  onClick,
  extraClassName,
  isDisabled,
}) => {
  return (
    <div className="Button" data-testid="Button">
      <button
        onClick={onClick}
        className={`buttonGradient rounded py-0 m-0 px-5 ${extraClassName}`}
        disabled={loading || isDisabled || false}
      >
        {loading ? <Spinner color="gold" /> : text}
      </button>
    </div>
  );
};

export const ButtonDropDown: FC<ButtonProps> = ({
  text,
  onClick,
  loading,
  price = null,
  casinoPrice = null,
}) => {
  const handleBasicClick = () => {
    if (onClick) {
      onClick("basic");
    }
  };

  const handleCasinoClick = () => {
    if (onClick) {
      onClick("casino");
    }
  };

  return (
    <Dropdown data-bs-theme="dark">
      <Dropdown.Toggle
        className="btn btnDropDown shadow-sm rounded p-2 "
        variant="primary"
        id="dropdown-basic"
      >
        <span className="mx-1">{text}</span>
        <i className="material-icons mx-1">
          {loading ? (
            <Spinner color="white" />
          ) : (
            <GlobeAmericas width={18} height={18} />
          )}
        </i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {price && (
          <Dropdown.Item onClick={handleBasicClick}>
            <div className="row">
              <div className="col-6">Basic</div>
              <div className="col-6 d-flex justify-content-end">
                {formatCurrency(price.amount, price.currency)}
              </div>
            </div>
            {/* <span>Basic</span> <span>({formatCurrency(price.amount, price.currency)}) */}
          </Dropdown.Item>
        )}
        {casinoPrice && casinoPrice.price.amount > 0 && (
          <Dropdown.Item onClick={handleCasinoClick}>
            <div className="row">
              <div className="col-6">Casino </div>
              <div className="col-6 d-flex justify-content-end">
                {formatCurrency(
                  casinoPrice.price.amount,
                  casinoPrice.price.currency
                )}
              </div>
            </div>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
