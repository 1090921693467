import { FC } from "react";
import "./Spinner.scss";
import { GlobeAmericas } from "react-bootstrap-icons";

interface SpinnerProps {
  color: "gold" | "white";
}

const Spinner: FC<SpinnerProps> = ({ color }) => {
  return (
    // <div className="spinnerContainer">
    //   <div className={`${color} spinner`}></div>
    // </div>
    <div className="spinnerContainer">
      <div className={`${color} nb-spinner`}></div>
    </div>
  );
};

export const TripleSpinnerSM: FC = () => {
  return (
    <div className="spinnerContainer">
      <div className="triple-spinner-small"></div>
    </div>
  );
};

export const TripleSpinner: FC = () => {
  return (
    <div className="spinnerContainer">
      {/* <div className="triple-spinner"></div> */}
      <div className="hm-spinner d-flex align-items-center justify-content-center">
        <GlobeAmericas className="text-primary icon-xl"/>
      </div>
    </div>
  );
};

export default Spinner;
