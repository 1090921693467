// src/redux/store.ts
import { createStore, applyMiddleware, combineReducers, Store } from "redux";
import thunk from "redux-thunk";
import { registrationReducer } from "./reducers/registrationReducer"; // Create this reducer
import { loginReducer } from "./reducers/loginReducer";
import {
  favoriteReducer,
  removeFavoriteReducer,
} from "./reducers/favoriteReducer";
import {
  getFavoritesReducer,
  favoriteCountReducer,
} from "./reducers/getFavoritesReducer";
import {
  bagReducer,
} from "./reducers/bagReducer";
import { getBacklinksReducer } from "./reducers/backlinksReducer";
import { sidebarCounterReducer } from "./reducers/sidebarReducer";
import { orderReducer } from "./reducers/orderReducer";
import { userReducer } from "./reducers/userReducer";

const rootReducer = combineReducers({
  registration: registrationReducer,
  login: loginReducer,
  favorites: favoriteReducer,
  removeFavorite: removeFavoriteReducer,
  getFavorites: getFavoritesReducer,
  favoriteCount: favoriteCountReducer,
  bag: bagReducer,
  backlinks: getBacklinksReducer,
  sideBar: sidebarCounterReducer,
  orders: orderReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

const store: Store<RootState> = createStore(
  rootReducer,
  applyMiddleware(thunk)
);

export default store;
