// src/api/user.ts
import axios from "axios";
import { RegistrationData } from "../interfaces/RegistrationData";
import { isTokenExpired } from "./auth";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = apiUrl;

export const registerUserAPI = async (userData: RegistrationData) => {
  try {

    // console.log('userDataAPI-REQUEST',userData);

    const response = await axios.post(
      `${BASE_URL}/api/user/register`,
      userData
    );
    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error: error.response.data.error,
    };
  }
};

export const loginUserAPI = async (userData: {
  email: string;
  password: string;
}) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/user/login`, userData);
    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error: error.response.data.error,
    };
  }
};

// Reset Password Controller
export const resetPasswordAPI = async (data: {
  token: string;
  newPassword: string;
}) => {

  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/reset-password`,
      data
    );
    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error:
        error.response?.data?.error ||
        "An error occurred while resetting the password.",
    };
  }
};

// Update User Controller
export const updateUserAPI = async (userData: {
  firstName: string;
  lastName: string;
  email: string;
}) => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/update-user`,
      userData,
      { headers }
    );

    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error:
        error.response?.data?.message ||
        "An error occurred while updating user data.",
    };
  }
};

// Update Password Controller
export const updatePasswordAPI = async (passwordData: {
  oldPassword: string;
  newPassword: string;
}) => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/update-password`,
      passwordData,
      { headers }
    );

    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error:
        error.response?.data?.message ||
        "An error occurred while updating the password.",
    };
  }
};

// Delete User Controller
export const deleteUserAPI = async (deleteData: { password: string }) => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/delete-user`,
      deleteData,
      { headers }
    );

    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error:
        error.response?.data?.message ||
        "An error occurred while deleting the user account.",
    };
  }
};

export const getUserDataAPI = async () => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/get-user-data`,
      {},
      { headers }
    );

    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error:
        error.response?.data?.message ||
        "An error occurred while getting user data.",
    };
  }
};

export const passwordRestoreAPI = async (email: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/generate-reset-token`,
      { email: email }
    );
    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error:
        error.response?.data?.error ||
        "An error occurred while resetting the password.",
    };
  }
};

export const verifyNewAccountAPI = async (token: string) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/user/verify/${token}`);
    return response.data;
  } catch (error: any) {
    return {
      success: false,
      error:
        error.response?.data?.error ||
        "An error occurred while account verification.",
    };
  }
}
