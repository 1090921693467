import { IBacklink } from "../../api/backlinks";
import { IOrderLink } from "../../api/order";
import { OrderActionTypes, OrderAction } from "../actions/orderActions";

// Add item to user's order
interface OrderState {
  addLoading: boolean;
  getLoading: boolean;
  removeLoading: boolean;

  reqOrder: IBacklink[];
  resOrders: IOrderLink[];
  resMessenge: string | null;

  addError: string | null;
  getError: string | null;
  removeError: string | null;

  updateLoading: boolean;
  updateError: string | null;
  updateMessage: string | null;

  reqOrdersAdminLoading: boolean;
  resOrdersAdminData: IOrderLink[] | [];
  resAdminError: string | null;
}

const initialOrderState: OrderState = {
  addLoading: false,
  getLoading: false,
  removeLoading: false,

  reqOrder: [],
  resOrders: [],
  resMessenge: null,

  addError: null,
  getError: null,
  removeError: null,

  updateLoading: false,
  updateError: null,
  updateMessage: null,

  reqOrdersAdminLoading: false,
  resOrdersAdminData: [],
  resAdminError: null,
};

export const orderReducer = (
  state = initialOrderState,
  action: OrderAction
): OrderState => {
  switch (action.type) {
    case OrderActionTypes.GET_ORDER_REQUEST:
      return { ...state, getLoading: true, getError: null };
    case OrderActionTypes.GET_ORDER_SUCCESS:
      return {
        ...state,
        getLoading: false,
        resOrders: action.payload,
        getError: null,
      };
    case OrderActionTypes.GET_ORDER_FAILURE:
      return { ...state, getLoading: false, getError: action.error };

    case OrderActionTypes.ADD_TO_ORDER_REQUEST:
      return { ...state, addLoading: true, addError: null };
    case OrderActionTypes.ADD_TO_ORDER_SUCCESS:
      return {
        ...state,
        addLoading: false,
        resMessenge: action.payload,
        addError: null,
      };
    case OrderActionTypes.ADD_TO_ORDER_FAILURE:
      return { ...state, addLoading: false, addError: action.error };

    case OrderActionTypes.REMOVE_FROM_ORDER_REQUEST:
      return { ...state, removeLoading: true, removeError: null };
    case OrderActionTypes.REMOVE_FROM_ORDER_SUCCESS:
      return {
        ...state,
        removeLoading: false,
        resOrders: state.resOrders.filter(
          (order) => order._id !== action.payload
        ),
        removeError: null,
      };
    case OrderActionTypes.REMOVE_FROM_ORDER_FAILURE:
      return { ...state, removeLoading: false, removeError: action.error };

    case OrderActionTypes.CLEAN_ORDER:
      return { ...state, resMessenge: null, addError: null };

    case OrderActionTypes.UPDATE_ORDER_REQUEST:
      return { ...state, updateLoading: true, updateError: null };
    case OrderActionTypes.UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateMessage: action.payload,
        updateError: null,
      };
    case OrderActionTypes.UPDATE_ORDER_FAILURE:
      return { ...state, updateLoading: false, updateError: action.error };

    case OrderActionTypes.RESET_UPDATE_ORDER:
      return { ...state, updateMessage: null, updateError: null };

    case OrderActionTypes.GET_ORDER_ADMIN_REQUEST:
      return { ...state, reqOrdersAdminLoading: true, resAdminError: null };
    case OrderActionTypes.GET_ORDER_ADMIN_SUCCESS:
      return {
        ...state,
        reqOrdersAdminLoading: false,
        resOrdersAdminData: action.payload,
        resAdminError: null,
      };
    case OrderActionTypes.GET_ORDER_ADMIN_FAILURE:
      return {
        ...state,
        reqOrdersAdminLoading: false,
        resAdminError: action.error,
      };

    default:
      return state;
  }
};
