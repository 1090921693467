import React, { FC, useEffect, useState } from "react";
import styles from "./Register.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { RegistrationData } from "../../../interfaces/RegistrationData";
import { registerUser } from "../../../redux/actions/registrationActions"; // Corrected import path
import { Dispatch } from "redux"; // Import Dispatch from Redux
import { Title } from "../../Ui/Title/Title";
import GenericInput from "../../Common/GenericInputs/GenericInputs";
import { ButtonGradient } from "../../Ui/Button/Button";
import { Alert } from "../../Ui/Alert/Alert";
import {
  isValidEmail,
  isValidPassword,
  isValidString,
} from "../../../utils/validation";
import { Link, useLocation } from "react-router-dom";
import { set } from "lodash";

interface RegisterFormProps {
  // onBackToLoginClick: () => void;
  // onSubmit: (formData: RegistrationData) => void;
}

const Register: FC<RegisterFormProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { isLoading, error, message } = useSelector(
    (state: RootState) => state.registration
  );

  const [formData, setFormData] = useState<RegistrationData>({
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    couponID: "",
  });

  const [displayError, setDisplayError] = useState<string | null>(null);
  const [displayMessage, setDisplayMessage] = useState<string | null>(null);
  const [displayWarning, setDisplayWarning] = useState<string | null>(null);
  const [isShowInputWarning, setIsShowInputWarning] = useState<boolean>(false);
  // const location = useLocation(); // Get the location object

  // useEffect(() => {
  //   // Parse the search string to get the query parameters
  //   const queryParams = new URLSearchParams(location.search);
  //   // Get the token from the URL
  //   const couponVIP = queryParams.get("couponVIP");

  //   if (couponVIP?.length) {
  //     setFormData({ ...formData, couponID: couponVIP });
  //   }
  // }, [location]);

  useEffect(() => {
    if (message?.length) {
      setDisplayError(null); // Clear error when there is a message
      setDisplayMessage(message);
    }
  }, [message]);

  useEffect(() => {
    if (error?.length) {
      setDisplayMessage(null); // Clear error when there is a message
      setDisplayError(error);
    }
  }, [error]);

  useEffect(() => {
    if (formData.confirmPassword === formData.password) {
      if (displayWarning) setDisplayWarning(null);
    }
  }, [formData.password, formData.confirmPassword, displayWarning]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsShowInputWarning(false);
    if (
      !isValidString(formData.firstName) ||
      !isValidString(formData.lastName) ||
      !isValidString(formData.username) ||
      !isValidEmail(formData.email) ||
      !isValidPassword(formData.password) ||
      !isValidPassword(formData.confirmPassword)
    ) {
      setIsShowInputWarning(true);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setDisplayWarning("Passwords do not match");
      return;
    }
    
    dispatch(registerUser(formData));
  };

  return (
    <div className={`${styles.Login}`} data-testid="RegisterForm">
      <Title title="LinkWayz" />

      <h3 className="msg-info mb-3">Create Account</h3>
      <form onSubmit={handleSubmit} className="row">
        <div className="form-group mb-4">
          <GenericInput
            type="text"
            id="usernameRegister"
            name="username"
            value={formData.username}
            placeholder="User Name"
            labelText="User Name:"
            onChange={handleChange}
            required
            isShowInputWarning={isShowInputWarning}
          />
        </div>

        <div className="col-6">
          <div className="form-group mb-4">
            <GenericInput
              type="text"
              id="firstNameRegister"
              name="firstName"
              value={formData.firstName}
              placeholder="First Name"
              labelText="First Name:"
              onChange={handleChange}
              required
              isShowInputWarning={isShowInputWarning}
            />
          </div>
        </div>

        <div className="col-6">
          <div className="form-group mb-4">
            <GenericInput
              type="text"
              id="lastNameRegister"
              name="lastName"
              value={formData.lastName}
              placeholder="Last Name"
              labelText="Last Name:"
              onChange={handleChange}
              required
              isShowInputWarning={isShowInputWarning}
            />
          </div>
        </div>

        <div className="form-group mb-4">
          <GenericInput
            type="email"
            id="emailRegister"
            name="email"
            value={formData.email}
            placeholder="Email Address"
            labelText="Email Address:"
            onChange={handleChange}
            required
            isShowInputWarning={isShowInputWarning}
          />
        </div>

        <div className="form-group mb-4">
          <GenericInput
            type="password"
            id="passwordRegister"
            name="password"
            value={formData.password}
            placeholder="Password"
            labelText="Password:"
            onChange={handleChange}
            required
            isShowInputWarning={isShowInputWarning}
          />
        </div>

        <div className="form-group mb-4">
          <GenericInput
            type="password"
            id="confirmPasswordRegister"
            name="confirmPassword"
            value={formData.confirmPassword}
            placeholder="Confirm Password"
            labelText="Confirm Your Password:"
            onChange={handleChange}
            required
            isShowInputWarning={isShowInputWarning}
          />
        </div>

        <div className="form-group mb-5">
          <GenericInput
            type="text"
            id="couponIDRegister"
            name="couponID"
            value={formData.couponID}
            placeholder="Special Key"
            labelText="Holding an special key?"
            onChange={handleChange}
            isShowInputWarning={false}
          />
        </div>
     
        {displayMessage && (
          <div className="mb-2">
            <Alert Message={displayMessage} alertType="success" />
          </div>
        )}
        {displayError && (
          <div className="mb-2">
            <Alert Message={displayError} alertType="danger" />
          </div>
        )}
        {displayWarning && (
          <div className="mb-2">
            <Alert Message={displayWarning} alertType="warning" />
          </div>
        )}
        <div className="form-group mb-4">
          <ButtonGradient
            text="Register"
            extraClassName="primary h5"
            loading={isLoading}
          />
        </div>
      </form>
      <div className="text-center">
        Already have an account?{" "}
        <Link to={"/Login"} className="text-decoration-none">
          <strong
            // onClick={onBackToLoginClick}
            className="text-primary cursor-pointer"
          >
            Login
          </strong>
        </Link>
      </div>
    </div>
  );
};

export default Register;
