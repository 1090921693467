import React, { FC } from "react";
import styles from "./BackGround.module.scss";

interface BackGroundProps {}

export const BackGroundAnimation: FC<BackGroundProps> = () => {
  return (
    <div className={styles.BackGround} data-testid="BackGround">
      <div className={styles.wrapperFullLayout}>
          <div className={styles.boxAnimations}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
    </div>
  );
};

export const BackGroundLayout: FC<BackGroundProps> = () => {
  return (
    <div className={`${styles.FrameLayout}`}></div>
  );
};
