import { FC } from "react";
import "./NumIcon.scss";
import { Dice6Fill } from "react-bootstrap-icons";

interface NumIconProps {
  multipler: number;
  siteType: string;
  contentType?: string;
}

export const NumIcon: FC<NumIconProps> = ({
  multipler,
  siteType,
  contentType,
}) => {
  if (siteType.toLocaleLowerCase() === "gambling") {
    return (
      <div
        className="iconNum-container d-flex align-items-center"
        data-testid="NumIcon"
      >
        <div className="icon position-relative pr-3">
          <Dice6Fill
            className={`icon-sm  ${
              contentType === "casino" ? "text-success" : ""
            }`}
          />
          <div className="multipler position-absolute">
            x{multipler.toFixed(2)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="iconNum-container" data-testid="NumIcon">
      -
    </div>
  );
};
