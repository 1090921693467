import { FC, useEffect, useState } from "react";
import styles from "./Settings.module.scss";
import Nav from "react-bootstrap/Nav";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import GeneralComponent from "./components/General/General";
import Password from "./components/Password/Password";
import DeleteAccount from "./components/DeleteAccount/DeleteAccount";
import { DashBoardMainTitle } from "../../Ui/Title/Title";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../redux/store";
import { getUserDataAsync } from "../../../redux/actions/userActions";

interface SettingsProps {}

const Settings: FC<SettingsProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const location = useLocation();

  const isNavItemActive = (path: string) => location.pathname === path;
  const [userName, setUserName] = useState<string>("");
  const { user } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(getUserDataAsync());
  }, [dispatch]);

  useEffect(() => {
    if (user) setUserName(user.username);
  }, [user]);

  return (
    <div className={`${styles.Settings} w-100 h-100`} data-testid="Settings">
      <DashBoardMainTitle title="Account Settings" />
      <div className="d-flex p-3">
        <div className="d-flex w-100">
          <Nav variant="pills" className="flex-column col-md-3 pr-3">
            <Nav.Item className="pr-1 mb-3">
              <Link
                to="/User-Profile/Settings"
                className={`nav-link shadow-sm p-3 ${
                  isNavItemActive("/User-Profile/Settings") ? "active" : ""
                }`}
              >
                <p className="h4 m-0">General</p>
              </Link>
            </Nav.Item>
            <Nav.Item className="pr-1 mb-3">
              <Link
                to="/User-Profile/Settings/Password"
                className={`nav-link shadow-sm p-3 ${
                  isNavItemActive("/User-Profile/Settings/Password")
                    ? "active"
                    : ""
                }`}
              >
                <p className="h4 m-0 ">Password</p>
              </Link>
            </Nav.Item>
            <Nav.Item className="pr-1">
              <Link
                to="/User-Profile/Settings/Delete"
                className={`nav-link shadow-sm p-3 ${
                  isNavItemActive("/User-Profile/Settings/Delete")
                    ? "active"
                    : ""
                }`}
              >
                <p className="h4 m-0 ">Account</p>
              </Link>
            </Nav.Item>
          </Nav>

          <div className="flex-grow-1 py-5 px-4 rounded shadow-sm mw-100">
            <div className="d-block mb-5">
              <div className={`${styles.circle} shadow`}>
                {userName?.charAt(0).toUpperCase()}
              </div>
            </div>
            <Routes>
              <Route path="/" element={<GeneralComponent />} />
              <Route path="Password" element={<Password />} />
              <Route path="Delete" element={<DeleteAccount />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
