import { UserActionTypes, UserAction } from "../actions/userActions";

interface UserState {
  loading: boolean;
  user: any;
  error: string | null;
  successMessage: string | null;
  deleteMessage: string | null;
  resetPasswordMessage: string | null;
  verifyEmailMessage: string | null;
  verifyEmailError: string | null;
}

const initialUserState: UserState = {
  loading: false,
  user: null,
  error: null,
  successMessage: null,
  deleteMessage: null,
  resetPasswordMessage: null,
  verifyEmailMessage: null,
  verifyEmailError: null,
};

export const userReducer = (
  state = initialUserState,
  action: UserAction
): UserState => {
  switch (action.type) {
    // RESET_PASSWORD
    case UserActionTypes.RESET_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case UserActionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPasswordMessage: action.payload,
        error: null,
      };
    case UserActionTypes.RESET_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.error };

    // UPDATE_USER
    case UserActionTypes.UPDATE_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case UserActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        error: null,
      };
    case UserActionTypes.UPDATE_USER_FAILURE:
      return { ...state, loading: false, error: action.error };

    // UPDATE_PASSWORD
    case UserActionTypes.UPDATE_PASSWORD_REQUEST:
      return { ...state, loading: true, error: null };
    case UserActionTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        successMessage: action.payload,
        error: null,
      };
    case UserActionTypes.UPDATE_PASSWORD_FAILURE:
      return { ...state, loading: false, error: action.error };

    // DELETE_USER
    case UserActionTypes.DELETE_USER_REQUEST:
      return { ...state, loading: true, error: null };
    case UserActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteMessage: action.payload,
        error: null,
      };
    case UserActionTypes.DELETE_USER_FAILURE:
      return { ...state, loading: false, error: action.error };

    // GET_USER_DATA
    case UserActionTypes.GET_USER_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case UserActionTypes.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null,
      };
    case UserActionTypes.GET_USER_DATA_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UserActionTypes.RESET_USER_STATE:
      return initialUserState;

    case UserActionTypes.PASSWORD_RESTORE_REQUEST:
      return { ...state, loading: true, error: null };
    case UserActionTypes.PASSWORD_RESTORE_SUCCESS:
      return {
        ...state,
        loading: false,
        resetPasswordMessage: action.payload,
        error: null,
      };
    case UserActionTypes.PASSWORD_RESTORE_FAILURE:
      return { ...state, loading: false, error: action.error };

    case UserActionTypes.VERIFY_EMAIL_REQUEST:
      return { ...state, loading: true, error: null };
    case UserActionTypes.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        verifyEmailMessage: action.payload,
        error: null,
      };
    case UserActionTypes.VERIFY_EMAIL_FAILURE:
      return { ...state, loading: false, verifyEmailError: action.error };

    default:
      return state;
  }
};
