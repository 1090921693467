import axios from "axios";
import { Types } from "mongoose";
import { isTokenExpired } from "./auth";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = apiUrl;

export interface FavoriteItem {
  _id?: Types.ObjectId;
  itemID: Types.ObjectId;
  contentType?: string;
}

export const addToFavorites = async (favoriteItem: FavoriteItem) => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/add-favorite`,
      favoriteItem,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeFromFavorites = async (favoriteItem: FavoriteItem) => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/delete-favorite`,
      favoriteItem,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllFavorites = async (): Promise<any> => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/get-favorites`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
