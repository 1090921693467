import {
  SideBarCounterTypes,
  SidebarCounterActionTypes,
} from "../actions/sidebarActions";

interface SidebarCounterState {
  loading: boolean;
  data: {
    bagCount: number;
    favoriteCount: number;
    orderedCount: number;
    userRank: string;
  };
  error: string | null;
}

// Initial state
const initialSidebarCounterState: SidebarCounterState = {
  loading: false,
  data: {
    bagCount: 0,
    favoriteCount: 0,
    orderedCount: 0,
    userRank: "",
  },
  error: null,
};

// Reducer function
export const sidebarCounterReducer = (
  state = initialSidebarCounterState,
  action: SidebarCounterActionTypes
): SidebarCounterState => {
  switch (action.type) {
    case SideBarCounterTypes.SIDEBAR_COUNTER_REQUEST:
      return { ...state, loading: true, error: null };
    case SideBarCounterTypes.SIDEBAR_COUNTER_SUCCESS:
      return { ...state, loading: false, data: action.payload.data, error: null };
    case SideBarCounterTypes.SIDEBAR_COUNTER_FAILURE:
      return { ...state, loading: false, error: action.error };
    case SideBarCounterTypes.ADD_TO_FAVORITES:
      return {
        ...state,
        data: {
          ...state.data,
          favoriteCount: state.data.favoriteCount
            ? state.data.favoriteCount + 1
            : 1,
        },
      };
    case SideBarCounterTypes.REMOVE_FROM_FAVORITES:
      return {
        ...state,
        data: {
          ...state.data,
          favoriteCount: state.data.favoriteCount
            ? state.data.favoriteCount - 1
            : 0,
        },
      };
    case SideBarCounterTypes.ADD_TO_BAG:
      return {
        ...state,
        data: {
          ...state.data,
          bagCount: state.data.bagCount ? state.data.bagCount + 1 : 1,
        },
      };
    case SideBarCounterTypes.REMOVE_FROM_BAG:
      return {
        ...state,
        data: {
          ...state.data,
          bagCount: state.data.bagCount ? state.data.bagCount - 1 : 0,
        },
      };
    case SideBarCounterTypes.ADD_TO_ORDER:
      const newOrderedCount = state.data.orderedCount + action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          orderedCount: newOrderedCount,
        },
      };
    case SideBarCounterTypes.REMOVE_FROM_ORDER:
      return {
        ...state,
        data: {
          ...state.data,
          orderedCount: state.data.orderedCount
            ? state.data.orderedCount - 1
            : 0,
        },
      };

    case SideBarCounterTypes.CLEAN_BAG:
      return {
        ...state,
        data: {
          ...state.data,
          bagCount: 0,
        },
      };
    default:
      return state;
  }
};
