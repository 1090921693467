// src/redux/actions/loginActions.ts
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { loginUserAPI } from "../../api/user"; // Implement this API function
import { UserData } from "../../interfaces/UserData";
import { Dispatch } from "redux";
import { ERROR_MESSAGES_LOGIN } from "../../components/Ui/Alert/Alert";

// Action Types
export enum LoginActionTypes {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILURE = "LOGIN_FAILURE",
}

// Define action interfaces
interface LoginRequestAction {
  type: LoginActionTypes.LOGIN_REQUEST;
}

interface LoginSuccessAction {
  type: LoginActionTypes.LOGIN_SUCCESS;
  payload: { token: string}; // Define the User type as needed
}

interface LoginFailureAction {
  type: LoginActionTypes.LOGIN_FAILURE;
  payload: string;
}

// Define a union type for all actions
export type LoginAction =
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction

// Action Creators
const loginRequest = (): LoginRequestAction => ({
  type: LoginActionTypes.LOGIN_REQUEST,
});

const loginSuccess = (data: {
  token: string;
}): LoginSuccessAction => ({
  type: LoginActionTypes.LOGIN_SUCCESS,
  payload: data,
});

const loginFailure = (error: string): LoginFailureAction => ({
  type: LoginActionTypes.LOGIN_FAILURE,
  payload: error,
});

export const loginUser = (credentials: {
  email: string;
  password: string;
  rememberMe: boolean;
}): ThunkAction<void, RootState, unknown, LoginAction> => {
  return async (dispatch: Dispatch<LoginAction>) => {
    try {
      dispatch(loginRequest());
      const response = await loginUserAPI(credentials);

      if (response.error) {
        dispatch(loginFailure(response.error));
        return;
      }

      // Handle success
      if (response.token) {
        dispatch(loginSuccess({ token: response.token}));
      } else {
        // Handle other cases as needed
        dispatch(loginFailure(ERROR_MESSAGES_LOGIN.serverError));
      }
    } catch (error: any) {
      // Handle login error
      dispatch(loginFailure(ERROR_MESSAGES_LOGIN.serverError));
    }
  };
};