import { FC, useState, Fragment, useEffect } from "react";
import "./Stepper.scss";
import { ButtonGradient } from "../../Ui/Button/Button";
import { CheckLg } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { disableTable, enableTable } from "../../../redux/actions/bagActions";

// interface PaymentBoxProps {}

interface StepProps {
  indicator: React.ReactNode | number;
  label: string;
  navigateToStepHandler: (index: number) => void;
  index: number;
  isActive: boolean;
  isComplete: boolean;
  isWarning?: boolean;
  isError?: boolean;
  isRightToLeftLanguage?: boolean;
}

const Step: FC<StepProps> = ({
  indicator,
  label,
  navigateToStepHandler,
  index,
  isActive,
  isComplete,
  isWarning,
  isError,
  isRightToLeftLanguage,
}) => {
  const classes = [""];

  if (isActive) {
    classes.push("is-active");
  }
  if (isComplete) {
    classes.push("is-complete");
  }
  if (isWarning) {
    classes.push("is-warning");
  }
  if (isError) {
    classes.push("is-error");
  }
  if (isRightToLeftLanguage) {
    classes.push("rightToLeft");
  }

  return (
    <div className={`stepper-step ${classes.join(" ")}`}>
      <div className="stepper-indicator">
        <span
          className="stepper-indicator-info"
          onClick={
            isComplete || isError
              ? () => navigateToStepHandler(index)
              : () => {} // Returning an empty function instead of null
          }
        >
          {isComplete ? <CheckLg className="stepper-tick" /> : indicator}
        </span>
      </div>
      <div className="stepper-label">{label}</div>
    </div>
  );
};

interface StepperHeadProps {
  stepperContent: any[];
  navigateToStepHandler: (index: number) => void;
  isVertical?: boolean;
  isInline?: boolean;
  isRightToLeftLanguage?: boolean;
  currentTabIndex: number;
}

const StepperHead: FC<StepperHeadProps> = ({
  stepperContent,
  navigateToStepHandler,
  isVertical,
  isInline,
  isRightToLeftLanguage,
  currentTabIndex,
}) => (
  <div
    className={`stepper-head ${isVertical ? "vertical-stepper-head" : ""} ${
      isInline ? "inline-stepper-head" : ""
    }`}
  >
    {stepperContent.map((el, i) => (
      <Step
        key={i}
        index={i}
        navigateToStepHandler={navigateToStepHandler}
        isActive={i === currentTabIndex}
        isComplete={el.isComplete}
        isWarning={el.isWarning}
        isError={el.isError}
        isRightToLeftLanguage={isRightToLeftLanguage}
        indicator={i + 1}
        label={el.label}
      />
    ))}
  </div>
);

interface StepperFooterProps {
  isPrevBtn?: boolean;
  previousStepHandler: () => void;
  isLastStep: boolean;
  nextStepHandler: () => void;
  submitHandler: () => void;
  stepperContent: any[]; // You can further type this based on your needs
  currentTabIndex: number;
}

interface StepperFooterProps {
  isPrevBtn?: boolean;
  previousStepHandler: () => void;
  isLastStep: boolean;
  nextStepHandler: () => void;
  submitHandler: () => void;
  stepperContent: any[]; // You can further type this based on your needs
  currentTabIndex: number;
}

const StepperFooter: FC<StepperFooterProps> = ({
  isPrevBtn,
  previousStepHandler,
  isLastStep,
  nextStepHandler,
  submitHandler,
  stepperContent,
  currentTabIndex,
}) => {
  const submitCurrentStep = async () => {
    await stepperContent[currentTabIndex].clicked();
    nextStepHandler();
  };

  return (
    <div
      className="stepper-footer d-flex flex-column-reverse"
      style={{ justifyContent: isPrevBtn ? "space-between" : "flex-end" }}
    >
      {/* Previous Button */}
      {isPrevBtn && (
        <ButtonGradient
          extraClassName="secondary h6 mt-3"
          onClick={previousStepHandler}
          text={`Back to ${stepperContent[currentTabIndex - 1].label}`}
          loading={false}
        />
      )}

      {/* Next and Submit Button */}
      {isLastStep ? //   onClick={submitHandler} // <ButtonGradient // Submit Button
      //   text="Submit"
      //   loading={false}
      //   extraClassName="h5 success"
      //   isDisabled={
      //     stepperContent.some((el) => !el.isComplete) ||
      //     stepperContent[currentTabIndex].isLoading
      //   }
      // />
      null : (
        // Next Button
        <ButtonGradient
          onClick={
            stepperContent[currentTabIndex].clicked
              ? submitCurrentStep
              : nextStepHandler
          }
          text={`Continue to ${stepperContent[currentTabIndex + 1].label}`}
          loading={false}
          extraClassName="h6 primary"
          isDisabled={
            !stepperContent[currentTabIndex].isComplete ||
            stepperContent[currentTabIndex].isLoading
          }
        />
      )}
    </div>
  );
};

interface StepperProps {
  isRightToLeftLanguage?: boolean;
  isVertical?: boolean;
  isInline?: boolean;
  stepperContent: any[]; // You can further type this based on your needs
  submitStepper: () => void;
}

export const Stepper: FC<StepperProps> = ({
  isRightToLeftLanguage,
  isVertical,
  isInline,
  stepperContent,
  submitStepper,
}) => {
  const [currentTabIndex, setCurrentTabIndex] = useState(0),
    isLastStep = currentTabIndex === stepperContent.length - 1,
    isPrevBtn = currentTabIndex !== 0;

  const dispatch = useDispatch<Dispatch<any>>();

  useEffect(() => {
    if (currentTabIndex > 0) {
      dispatch(disableTable());
    } else {
      dispatch(enableTable());
    }
  }, [currentTabIndex]);

  const navigateToStepHandler = (index: number) => {
    if (index !== currentTabIndex) {
      setCurrentTabIndex(index);
    }
  };

  const nextStepHandler = () => {
    setCurrentTabIndex((prev: any) => {
      if (prev !== stepperContent.length - 1) {
        return prev + 1;
      }
    });
  };

  const previousStepHandler = () => {
    setCurrentTabIndex((prev) => prev - 1);
  };

  const submitHandler = () => {
    submitStepper();
  };

  return (
    <div className="stepper-wrapper">
      <div style={{ display: isVertical ? "flex" : "block" }}>
        <StepperHead
          stepperContent={stepperContent}
          navigateToStepHandler={navigateToStepHandler}
          isVertical={isVertical}
          isInline={isInline}
          currentTabIndex={currentTabIndex}
          isRightToLeftLanguage={isRightToLeftLanguage}
        />
        <div className="stepper-body">
          {stepperContent.map((el, i) => (
            <Fragment key={i}>{i === currentTabIndex && el.content}</Fragment>
          ))}
        </div>
      </div>
      <StepperFooter
        isPrevBtn={isPrevBtn}
        previousStepHandler={previousStepHandler}
        isLastStep={isLastStep}
        nextStepHandler={nextStepHandler}
        submitHandler={submitHandler}
        stepperContent={stepperContent}
        currentTabIndex={currentTabIndex}
      />
    </div>
  );
};

export default Stepper;
