import { FC, useEffect, useState } from "react";
import "./PaymentBox.scss";
import { Stepper } from "../../Lib/Stepper/Stepper";
import { Check2Circle, XCircle } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { IBacklink } from "../../../api/backlinks";
import { RootState } from "../../../redux/store";
import { formatCurrency } from "../../../services/currency";
import paypalLogo from "../../../assets/paypal-logo.png";
import bankTransferLogo from "../../../assets/bank-transfer-logo.png";
import skillLogo from "../../../assets/skrill-logo.png";
import { addToOrderAsync } from "../../../redux/actions/orderActions";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Modal from "react-bootstrap/Modal";
import { ButtonGradient } from "../../Ui/Button/Button";
import { Link } from "react-router-dom";

interface PaymentBoxProps {
  bagItems: IBacklink[];
  discount: number;
}

type PaymentMethod = "PayPal" | "BankTransfer" | "Skrill";

const PaymentBox: FC<PaymentBoxProps> = ({ bagItems, discount = 0 }) => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { bagCount, sumPrice } = useSelector((state: RootState) => state.bag);
  const { resMessenge } = useSelector((state: RootState) => state.orders);
  const [modalShowErr, setModalShowErr] = useState(false);
  const [finalSum, setFinalSum] = useState(sumPrice.amount);

  const [acceptFirstTerms, setAcceptFirstTerms] = useState({
      checked: true,
      touched: false,
    }),
    [acceptSecondTerms, setAcceptSecondTerms] = useState({
      checked: false,
      touched: false,
    }),
    [acceptThirdTerms, setAcceptThirdTerms] = useState({
      checked: false,
      touched: false,
    });

  useEffect(() => {
    if (sumPrice.amount) {
      setFinalSum(sumPrice.amount);

      if (discount > 0) {
        setFinalSum(sumPrice.amount - (sumPrice.amount * discount) / 100);
      }
    }
  }, [sumPrice]);

  useEffect(() => {
    if (resMessenge?.length) {
      thirdTermsHandler();
    }
  }, [resMessenge]);

  // const firstTermsHandler = () => {
  //   setAcceptFirstTerms((prev) => ({ checked: !prev.checked, touched: true }));
  // };

  const secondTermsHandler = (paymentMethod: PaymentMethod) => {
    if (paymentMethod) {
      setSelectedPaymentMethod(paymentMethod);
      setAcceptSecondTerms({ checked: true, touched: true });
    }
  };

  const thirdTermsHandler = () => {
    setAcceptThirdTerms({ checked: true, touched: true });
  };

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null);

  //for demo purposes only
  // const timeout = (ms: any) => {
  //   return new Promise((resolve) => setTimeout(resolve, ms));
  // };

  const secondStepAsyncFunc = async () => {
    //it can be an API call
    // setIsSecondStepLoading(true);
    // await timeout(1000);
    // setIsSecondStepLoading(false);
    // console.log("second step clicked");
  };

  const renderBankTransferPayment = () => {
    // const [hasPaid, setHasPaid] = useState(false);

    // // This function would be called when the customer
    // // clicks a button after making the bank transfer
    // const confirmPayment = async () => {
    //   // Update the order status to 'Awaiting Confirmation' or similar
    //   const response = await updateOrderStatus(order.id, "AWAITING_PAYMENT");
    //   if (response.success) {
    //     setHasPaid(true);
    //     // Optionally, send an email to the customer and finance team
    //   }
    // };

    return (
      <div>
        <p className="text-primary text-center p-5 h5">
          Bank transfer payments coming soon. Stay tuned! Meanwhile, try our
          PayPal method.
        </p>
      </div>
      // <div>
      //   <h3>Bank Transfer Details</h3>
      //   <p>Please transfer the amount to the following account:</p>
      //   <ul>
      //     <li>Account Name: XYZ Corp</li>
      //     <li>Account Number: 123456789</li>
      //     <li>Bank: ABC Bank</li>
      //     <li>SWIFT/BIC: ABCD1234</li>
      //     {/* Include additional bank details as needed */}
      //   </ul>
      //   <p>
      //     Please use the following reference when making the transfer: <strong>{order.referenceNumber}</strong>
      //   </p>
      //   {!hasPaid && (
      //     <button onClick={confirmPayment}>I have made the transfer</button>
      //   )}
      //   {hasPaid && <p>Thank you! We will process your order once the payment has been confirmed.</p>}
      // </div>
    );
  };

  const renderSkrillPayment = () => {
    return (
      <div>
        <p className="text-primary text-center p-5 h5">
          Skrill payments coming soon. Stay tuned! Meanwhile, try our PayPal
          method for secure transactions.
        </p>
      </div>
      // <form
      //   action="https://www.skrill.com/en/"
      //   method="get"
      //   target="_blank"
      //   className="skrill-form"
      // >
      //   <input
      //     type="hidden"
      //     name="pay_to_email"
      //     value="
      //   "
      //   />
      //   <input type="hidden" name="status_url" value="" />
      //   <input type="hidden" name="language" value="EN" />
      //   <input type="hidden" name="amount" value={sumPrice.amount} />
      //   <input type="hidden" name="currency" value={sumPrice.currency} />
      //   <input type="hidden" name="detail1_description" value="Order ID" />
      //   <input type="hidden" name="detail1_text" value="123456" />
      //   <input type="hidden" name="logo_url" value="" />
      //   <input type="hidden" name="prepare_only" value="1" />
      //   <input type="hidden" name="bnf_email" value="" />
      //   <input type="hidden" name="recipient_description" value="" />
      //   <input type="hidden" name="confirmation_note" value="" />
      //   <input type="hidden" name="dynamic_descriptor" value="" />
      //   <input type="hidden" name="pay_from_email" value="" />
      //   <input type="hidden" name="transaction_id" value="" />
      //   <input type="hidden" name="merchant_fields" value="" />
      //   <input type="hidden" name="payment_methods" value="" />
      //   <input type="hidden" name="title" value="" />
      //   <input type="hidden" name="firstname" value="" />
      //   <input type="hidden" name="lastname" value="" />
      //   <input type="hidden" name="address" value="" />
      //   <input type="hidden" name="city" value="" />
      //   <input type="hidden" name="country" value="" />
      //   <input type="hidden" name="postal_code" value="" />
      //   <input type="hidden" name="phone_number" value="" />
      //   <input type="hidden" name="date_of_birth" value="" />

      //   <button type="submit" className="btn btn-primary">
      //     Pay with Skrill
      //   </button>
      // </form>
    );
  };

  const renderPayPalPayment = () => {
    
    // const paypalClientIdSandbox = process.env.PAYPAL_CLIENT_ID_SANDBOX;
    // const paypalClientIdProduction = process.env.PAYPAL_CLIENT_ID;
    const paypalClientIdProduction = "ARY5Yxf4ve04ppMRJm_5g4Kzxc6ghjk1W-1Y3zjPpgrxdStr_GHTPvM7G6g8K89bvRFKEtREj12e5I47";
    return (
      <PayPalScriptProvider
        options={{
          clientId: paypalClientIdProduction,
        }}
      >
        {/* The rest of your component */}
        <PayPalButtons
          style={{ layout: "vertical" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: { value: Math.round(finalSum).toString() }, // The total amount to be charged
                },
              ],
            });
          }}
          onApprove={async (data, actions) => {
            if (actions.order) {
              try {
                const order = await actions.order.capture();
                if (order) {
                  // Handle successful transaction
                  // console.log("Transaction completed by ", order);

                  // submitPayment(order.id);
                  await dispatch(addToOrderAsync(bagItems, order.id));
                }
              } catch (err) {
                setModalShowErr(true);
                console.error(err);
              }
            }
          }}
        />
        {/* The rest of your component */}
      </PayPalScriptProvider>
    );
  };

  const stepperContent = [
    {
      label: "Summary",
      content: (
        <div className="stepBody">
          <h3 className="payment-title mt-2 mb-4 text-center">Order Summary</h3>
          <ol className="list-group list-group-flush mb-4">
            <li className="list-group-item d-flex justify-content-between align-items-start  py-4">
              <div className=" me-auto">
                <div className="h6 m-0">
                  Professional native copy writers
                </div>
              </div>
              <span className="text-success d-flex align-items-center">
                <Check2Circle className="icon-md" />
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-start py-4">
              <div className=" me-auto">
                <div className="h6 m-0">Revisions included</div>
              </div>
              <strong className="px-2 text-success h5 m-0">2</strong>
              <span className="text-success d-flex align-items-center">
                <Check2Circle className="icon-md" />
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-start py-4">
              <div className=" me-auto">
                <div className="h6 m-0">Words included up to</div>
              </div>
              <strong className="px-2 text-success h5 m-0">500</strong>
              <span className="text-success d-flex align-items-center">
                <Check2Circle className="icon-md" />
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-start  py-4">
              <div className=" me-auto">
                <div className="h6 m-0">Backlinks Count</div>
              </div>
              <strong className="text-primary h5 m-0">{bagCount}</strong>
              {/* <span className="text-success d-flex align-items-center">
                <Check2Circle className="icon-md" />
              </span> */}
            </li>
          </ol>
          <div className="mb-4">
            <h4 className={`mb-0 mt-3 mt-md-0 small-text-text text-center text-white ${discount ? "h5" : ""}`}>
              Total Amount:{" "}
              <strong className="headings-text cart-total">
                <span className="text-primary">
                  {formatCurrency(sumPrice.amount, sumPrice.currency)}
                </span>
              </strong>
            </h4>
            {discount > 0 && (
              <h5 className="mb-0 mt-4 mt-md-4 small-text-text text-center h4 text-white">
                Your Exclusive VIP Total Price:{" "}
                <strong className="headings-text cart-total">
                  <span className="text-primary">{formatCurrency(finalSum, sumPrice.currency)}</span>
                </strong>
              </h5>
            )}
          </div>

          {/* <div className="mb-4">
            <label>
              <input
                type="checkbox"
                // checked={acceptFirstTerms.checked}
                onChange={firstTermsHandler}
              />{" "}
              Accept first terms and conditions
            </label>
          </div> */}
        </div>
      ),
      isError: !acceptFirstTerms.checked && acceptFirstTerms.touched,
      isComplete: acceptFirstTerms.checked,
    },
    {
      label: "Payment Method",
      content: (
        <div className="stepBody">
          <h4 className="payment-title mt-2 mb-5 text-center text-white">
            Choose your payment method
          </h4>
          <div className={"PaymentMethods"}>
            <div className="pymt-radio">
              <div className="row-payment-method payment-row px-3">
                <div className="select-icon">
                  <input
                    type="radio"
                    id="radio1"
                    name="radios"
                    value="pp"
                    onChange={() => {
                      secondTermsHandler("PayPal");
                    }}
                    checked={selectedPaymentMethod === "PayPal"}
                  />
                  <label htmlFor="radio1"></label>
                </div>
                <div className="select-txt">
                  <p className="pymt-type-name">Paypal</p>
                  <p className="pymt-type-desc">
                    Safe payment online. Credit card needed. PayPal account is
                    not necessary.
                  </p>
                </div>
                <div className="select-logo d-flex justify-content-end">
                  <img src={paypalLogo} alt="PayPal" />
                </div>
              </div>
            </div>
            <div className="pymt-radio">
              <div className="row-payment-method payment-row-last px-3">
                <div className="select-icon">
                  <input
                    type="radio"
                    id="radio3"
                    name="radios"
                    value="sk"
                    checked={selectedPaymentMethod === "Skrill"}
                    onChange={() => {
                      secondTermsHandler("Skrill");
                    }}
                  />
                  <label htmlFor="radio3"></label>
                </div>
                <div className="select-txt">
                  <p className="pymt-type-name">Skrill</p>
                  <p className="pymt-type-desc">
                    Safe and secure transactions through Skrill. No Skrill
                    account needed, simply use any major credit or debit card.
                  </p>
                </div>
                <div className="select-logo d-flex justify-content-end">
                  <img src={skillLogo} alt="Skrill" />
                </div>
              </div>
            </div>
            <div className="pymt-radio mb-4">
              <div className="row-payment-method payment-row px-3">
                <div className="select-icon hr">
                  <input
                    type="radio"
                    id="radio2"
                    name="radios"
                    value="pp"
                    checked={selectedPaymentMethod === "BankTransfer"}
                    onChange={() => {
                      secondTermsHandler("BankTransfer");
                    }}
                  />
                  <label htmlFor="radio2"></label>
                </div>
                <div className="select-txt hr">
                  <p className="pymt-type-name">Bank Transfer</p>
                  <p className="pymt-type-desc">
                    Safe money transfer using your bank account. Safe payment
                    online. Credit card needed. Visa, Maestro, Discover,
                    American Express
                  </p>
                </div>
                <div className="select-logo d-flex justify-content-end">
                  <div className="select-logo-sub logo-spacer">
                    <img src={bankTransferLogo} alt="Visa" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      isError: !acceptSecondTerms.checked && acceptSecondTerms.touched,
      isComplete: acceptSecondTerms.checked,
    },
    {
      label: "Order",
      content: (
        <div className="stepBody my-4">
          {/* {addLoading && <TripleSpinner />}

          {addError && (
            <div className="alert alert-danger text-center mb-0" role="alert">
              {addError}
            </div>
          )}

          {resMessenge && (
            <div className="alert alert-success text-center" role="alert">
              {resMessenge}
            </div>
          )} */}

          {selectedPaymentMethod === "PayPal" &&
            // !addLoading &&
            // !resMessenge &&
            renderPayPalPayment()}

          {selectedPaymentMethod === "BankTransfer" &&
            renderBankTransferPayment()}

          {selectedPaymentMethod === "Skrill" &&
            // !addLoading &&
            // !resMessenge &&
            renderSkrillPayment()}
        </div>
      ),
      clicked: () => secondStepAsyncFunc(),
      isError: !acceptThirdTerms.checked && acceptThirdTerms.touched,
      isComplete: acceptThirdTerms.checked,
    },
  ];

  const submitStepper = async () => {
    try {
    } catch (error) {
      console.error("An error occurred while handling payment:", error);
    }
  };

  function ErrorModal(props: any) {
    return (
      <Modal
        {...props}
        size="l"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-danger">
          <div className="w-100 p-4 d-flex justify-content-center align-items-center text-white">
            <XCircle className="icon-xxl" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center py-4">
            <h3>Oops!</h3>
            <p className="h5">
              Purchase failed. Please try again or contact support.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Go to orders</Button> */}
          <div className="d-flex justify-content-center w-100 p-2">
            <Link to={`/User-Profile/Bag`}>
              <ButtonGradient
                onClick={() => setModalShowErr(false)}
                text="Close"
                loading={false}
                extraClassName="h6 primary"
              />
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <div
      className="PaymentBox bg-color-darker p-3 border-1 shadow "
      data-testid="PaymentBox"
    >
      <ErrorModal show={modalShowErr} onHide={() => setModalShowErr(false)} />

      <div className="row">
        <div className="col-12">
          <Stepper
            stepperContent={stepperContent}
            submitStepper={submitStepper}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentBox;
