import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../redux/store"; // Update path based on your file structure
import { IDetails, IOrderLink } from "../../../../api/order"; // Update path based on your file structure
// import { MenuItem } from "../../../Lib/MantineTable/MantineTable";
// import { Trash3 } from "react-bootstrap-icons";
import { formatCurrency } from "../../../../services/currency";
import { DashBoardMainTitle } from "../../../Ui/Title/Title";
import { DashboardBodyAlert } from "../../../Ui/Alert/Alert";
import {
  getOrderAdminAsync,
  // removeFromOrderAsync,
  resetUpdateOrder,
  updateOrderAsync,
} from "../../../../redux/actions/orderActions";
// import _ from "lodash";
import GenericInput from "../../../Common/GenericInputs/GenericInputs";
import { ButtonGradient } from "../../../Ui/Button/Button";
import { Types } from "mongoose";
import { AutohideExample } from "../../../Ui/Toast/Toast";
import { MantineProvider } from "@mantine/core";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
// import { NumIcon } from "../../../Ui/NumIcon/NumIcon";
import "./Orders.scss";

interface OrdersAdminProps {}

interface ToastInstance {
  id: number;
  message: string;
  variant: string;
}

//TODO MOVE ALL ICONS TO ICONS COMPONENTS UNDER UI FOLDER
// const removeFavoriteIcon: MenuItem = {
//   icon: Trash3,
//   tooltipId: "trash-tooltip",
//   text: "Remove From Order",
// };

const OrdersAdmin: FC<OrdersAdminProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const {
    reqOrdersAdminLoading,
    resOrdersAdminData,
    resAdminError,
    updateLoading,
    updateMessage,
    updateError,
  }: {
    reqOrdersAdminLoading: boolean;
    resOrdersAdminData: IOrderLink[] | [];
    resAdminError: string | null;
    updateLoading: boolean;
    updateMessage: string | null;
    updateError: string | null;
  } = useSelector((state: RootState) => state.orders);

  const [toasts, setToasts] = useState<ToastInstance[]>([]);

  useEffect(() => {
    dispatch(getOrderAdminAsync());
  }, [dispatch]);

  useEffect(() => {
    if (updateMessage?.length) {
      setToasts((prevToasts) => [
        ...prevToasts,
        {
          id: Date.now(),
          message: "Product saved successfully.",
          variant: "Success",
        },
      ]);

      dispatch(resetUpdateOrder());
    }
  }, [updateMessage, dispatch]);

  useEffect(() => {
    if (updateError?.length) {
      setToasts((prevToasts) => [
        ...prevToasts,
        {
          id: Date.now(),
          message: "Oops, something went wrong. Try again later.",
          variant: "Danger",
        },
      ]);

      dispatch(resetUpdateOrder());
    }
  }, [updateError, dispatch]);

  const removeToast = (id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // const debouncedDeleteOrder = _.debounce(async (orderedItem: IOrderLink) => {
  //   dispatch(removeFromOrderAsync(orderedItem._id));
  // }, 300);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);
    const form = e.target as HTMLFormElement;
    // const formData = new FormData(form);

    // Extracting values from formData
    const details: IDetails = {
      anchor: formData.get("anchor") as string,
      dateOfPublication: formData.get("dateOfPublication") as unknown as Date, // or convert to Date object if necessary
      content: formData.get("content") as string,
      targetUrl: formData.get("targetUrl") as string,
    };

    const rowId: Types.ObjectId = form.getAttribute(
      "data-rowid"
    ) as unknown as Types.ObjectId;

    dispatch(updateOrderAsync(rowId, details));
  };

  const renderSubComponent = ({ row }: { row: any }) => {
    const rowId: Types.ObjectId = row.original._id;
    return (
      <div className="p-4">
        <h3 className="mb-5">Please fill the fields and save</h3>
        <form
          onSubmit={handleSubmit}
          className="row d-flex justify-content-center"
          data-rowid={rowId}
        >
          <div className="row mb-5">
            <div className="col-4">
              <GenericInput
                type="text"
                id="anchor"
                name="anchor"
                value={
                  row.original.details?.anchor
                    ? row.original.details?.anchor
                    : ""
                }
                placeholder="Enter Anchor"
                labelText="Anchor"
                required={false}
                disabled={true}
                error={null}
              />
            </div>
            <div className="col-4">
              <GenericInput
                type="text"
                id="targetUrl"
                name="targetUrl"
                value={
                  row.original.details?.targetUrl
                    ? row.original.details?.targetUrl
                    : ""
                }
                placeholder="Enter Target Url"
                labelText="Target Url"
                required={false}
                disabled={true}
                error={null}
              />
            </div>
            <div className="col-4">
              <GenericInput
                type="date"
                id="date"
                name="dateOfPublication"
                value={
                  row.original.details?.dateOfPublication
                    ? new Date(row.original.details.dateOfPublication)
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
                placeholder="Select Date of Publication"
                labelText="Select Date of Publication"
                required={false}
                disabled={true}
                error={null}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12">
              <GenericInput
                type="textarea"
                id="content"
                name="content"
                value={
                  row.original.details?.content
                    ? row.original.details?.content
                    : ""
                }
                placeholder="Enter Custom Content"
                labelText="Custom Content (Optional)"
                required={false}
                disabled={false}
                error={null}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <ButtonGradient
                  text="Save"
                  extraClassName="primary h6 w-auto"
                  loading={updateLoading}
                  isDisabled={true}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const columnsMantine = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "userName",
        header: "User Name",
        enableEditing: false,
        enableClickToCopy: true,
      },
      {
        accessorKey: "userEmail",
        header: "User Email",
        enableEditing: false,
        enableClickToCopy: true,
      },
      {
        accessorKey: "website",
        header: "Website",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <div>
              <a
                href={`https://www.${renderedCellValue?.toString()}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fw-bold text-primary cursor-pointer text-decoration-none"
              >
                {renderedCellValue}
              </a>
            </div>
          );
        },
        enableEditing: false
      },
      {
        accessorFn: (row) => row.price.amount,
        header: "Price",
        Cell: ({ renderedCellValue, row }) => {
          const { amount, currency } = row.original.price;

          return <div>{formatCurrency(amount, currency)}</div>;
        },
        filterVariant: "range-slider",
        filterFn: "betweenInclusive", // default (or between)
        mantineFilterRangeSliderProps: {
          max: 10_000, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 10,
        },
        enableEditing: false
      },
      {
        accessorKey: "contentType",
        header: "Content Type",
        enableEditing: false
      },
      {
        accessorFn: (row) => new Date(row.purchaseDate),
        header: "Order Date",
        Cell: ({ cell }) => cell.getValue<Date>().toLocaleDateString(), // convert back to string for display
        filterVariant: "date-range",
        enableEditing: false
      },
      {
        id: "publicationDate",
        accessorFn: (row) => new Date(row.details?.dateOfPublication), //convert to date for sorting and filtering
        header: "Publication Date",
        Cell: ({ cell }) => {
          // console.log('cell.getValue', cell.getValue<Date>())
          if (cell.getValue<Date>().toLocaleDateString() === "Invalid Date") {
            return <span></span>;
          }
          return cell.getValue<Date>().toLocaleDateString()
        }, // convert back to string for display
        filterVariant: "date-range",
        enableEditing: false
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: (info: any) => {
          const status = info.row.original.status;
          if (!status) {
            return <span>-</span>;
          }
          if (status === "Pending") {
            return (
              <div
                className="alert alert-warning p-2 m-0 text-center rounded"
                role="alert"
              >
                <span className="text-warning fw-bold">{status}</span>
              </div>
            );
          }
          if (status === "Expired") {
            return (
              <div
                className="alert alert-danger p-2 m-0 text-center rounded"
                role="alert"
              >
                <span className="text-danger fw-bold">{status}</span>
              </div>
            );
          }
          if (status === "Canceled") {
            return (
              <div
                className="alert alert-success p-2 m-0 text-center rounded"
                role="alert"
              >
                <span className="text-success fw-bold">{status}</span>
              </div>
            );
          }
          if (status === "Completed") {
            return (
              <div
                className="alert alert-success p-2 m-0 text-center rounded"
                role="alert"
              >
                <span className="text-success fw-bold">{status}</span>
              </div>
            );
          }
          if (status === "New") {
            return (
              <div
                className="alert alert-success p-2 m-0 text-center rounded"
                role="alert"
              >
                <span className="text-success fw-bold">{status}</span>
              </div>
            );
          }
          
        },
        mantineFilterSelectProps: {
          data: ["Pending", "Expired", "Canceled", "Completed", "New"],
        },
        mantineEditSelectProps: {
          data: ["Pending", "Expired", "Canceled", "Completed", "New"],
        },
        enableEditing: true,
        editVariant: "select",
        filterVariant: "select",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    columns: columnsMantine,
    data: resOrdersAdminData,
    enableStickyHeader: true,
    initialState: {
      showColumnFilters: true,
      pagination: { pageIndex: 0, pageSize: 50 },
      sorting: [
        { id: "publicationDate", desc: false }, //sort by state in ascending order by default
      ],
    },
    mantineTableContainerProps: {
      sx: { maxHeight: "79vh" }, //give the table a max height
    },
    isMultiSortEvent: () => true,
    enableEditing: true,
    enableTopToolbar: true,
    mantineTableHeadCellProps: {
      sx: {
        overflow: "visible",
        fontSize: "15px !important",
        "@media (max-width: 800px)": {
          fontSize: "14px !important",
        },
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        overflow: "visible",
        fontSize: "15px !important",
        "@media (max-width: 800px)": {
          fontSize: "14px !important",
        },
      },
    },
    displayColumnDefOptions: {
      "mrt-row-expand": {
        mantineTableHeadCellProps: {
          align: "right",
        },
        mantineTableBodyCellProps: {
          align: "right",
        },
      },
    },

    renderDetailPanel: ({ row }) => renderSubComponent({ row: row }),
    positionExpandColumn: "last",
  });

  const renderBody = () => {
    if (reqOrdersAdminLoading) {
      return <DashboardBodyAlert alertType={"loader"} />;
    }

    if (resAdminError) {
      return (
        <DashboardBodyAlert
          Message={"Something went wrong! Please try again later."}
          alertType={"error"}
        />
      );
    }

    if (!resOrdersAdminData.length) {
      return (
        <DashboardBodyAlert
          Message={"Your orders is empty."}
          alertType={"empty"}
        />
      );
    }

    return (
      <div className="p-3">
        <div className="overflow-auto shadow">
          <div>
            {toasts.map((toast, index) => (
              <AutohideExample
                key={toast.id}
                message={toast.message}
                removeToast={() => removeToast(toast.id)}
                index={index}
                variant={toast.variant}
              />
            ))}
          </div>
          <MantineProvider
            theme={{
              colorScheme: "dark",
              primaryColor: "orange",
              primaryShade: 3,
            }}
          >
            <MantineReactTable table={table} />
          </MantineProvider>
        </div>
      </div>
    );
  };

  return (
    <div className={``} data-testid="OrdersAdmin">
      <DashBoardMainTitle title="Orders" />
      {renderBody()}
    </div>
  );
};

export default OrdersAdmin;
