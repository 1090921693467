import { FC, ChangeEvent } from "react";
import styles from "./Input.module.scss";

interface InputProps {
  text: string;
  name: string;
  id: string;
  checked: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void; // Specifying that it only accepts input elements
}

export const Input: FC<InputProps> = ({
  text,
  name,
  id,
  checked,
  onChange,
}) => {
  return (
    <div className={styles.Input} data-testid="Input">
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange} // Using the onChange prop here
      />
      <label className="text-white" htmlFor={id}> {/* Updated to refer correctly */}
        {text || "Insert Text"}
      </label>
    </div>
  );
};
