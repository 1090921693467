import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { loginUser } from "../../../redux/actions/loginActions";
import styles from "./Login.module.scss";
import { ButtonGradient } from "../../Ui/Button/Button";
import { Dispatch } from "redux"; // Import Dispatch from Redux
import { Input } from "../../Ui/Input/Input";
import GenericInput from "../../Common/GenericInputs/GenericInputs";
import { Title } from "../../Ui/Title/Title";
import { Alert } from "../../Ui/Alert/Alert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { verifyEmailAsync } from "../../../redux/actions/userActions";
// import { makeData } from "../../Lib/Table/makeData";

interface LoginProps {
  // onRegisterClick: () => void;
  // onPasswordRestoreClick: () => void;
}

const Login: FC<LoginProps> = ({}) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const location = useLocation(); // Get the location object
  const dispatch = useDispatch<Dispatch<any>>();
  const { isLoading, error, token } = useSelector(
    (state: RootState) => state.login
  );

  const { loading, verifyEmailError, verifyEmailMessage } = useSelector(
    (state: RootState) => state.user
  );

  const [formData, setFormData] = useState<{
    email: string;
    password: string;
    rememberMe: boolean;
  }>({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [tokenRegisterVerify, setTokenRegisterVerify] = useState<string>("");

  useEffect(() => {
    // Parse the search string to get the query parameters
    const queryParams = new URLSearchParams(location.search);
    // Get the token from the URL
    const tokenVerify = queryParams.get("tokenVerify");
    if (tokenVerify) setTokenRegisterVerify(tokenVerify);
  }, [location]);

  useEffect(() => {
    if (tokenRegisterVerify) {
      dispatch(verifyEmailAsync(tokenRegisterVerify));
    }
  }, [tokenRegisterVerify, dispatch]);


  useEffect(() => {
    if (token) {
      cookies.set("TOKEN", token, {
        path: "/",
      });

      navigate("/User-Profile");
    }
  }, [token, navigate, cookies]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, checked } = e.target as HTMLInputElement;

    if (name === "rememberMe") {
      setFormData((prevData) => ({ ...prevData, [name]: checked }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(loginUser(formData));
  };

  return (
    <div className={`${styles.Login} `} data-testid="Login">
      <Title title="LinkWayz"/>

      <form onSubmit={handleSubmit} className="row">
        <div className="form-group mb-4">
          <GenericInput
            type={"email"}
            id={"emailLogIn"}
            name={"email"}
            placeholder={"Email"}
            labelText={"Email Address:"}
            required={true}
            value={formData.email}
            onChange={handleChange}
            disabled={false}
          />
        </div>

        <div className="form-group mb-4">
          <GenericInput
            type="password"
            id="passwordLogIn"
            name="password"
            placeholder="Password"
            labelText="Password:"
            value={formData.password}
            onChange={handleChange}
            required={true}
            // onPasswordRestoreClick={onPasswordRestoreClick}
            ForgotPassword={"/Password-Restore"}
          />
        </div>

        <div className="form-group mb-4">
          <Input
            text="Remember me"
            name="rememberMe"
            id="rememberMeLogIn"
            checked={formData.rememberMe}
            onChange={handleChange}
          />
        </div>

        {error && (
          <div className="mb-2">
            <Alert Message={error} alertType="danger" />
          </div>
        )}

        {verifyEmailError && (
          <div className="mb-2">
            <Alert Message={verifyEmailError} alertType="danger" />
          </div>
        )}

        {verifyEmailMessage && (
          <div className="mb-2">
            <Alert Message={verifyEmailMessage} alertType="success" />
          </div>
        )}

        <div className="form-group mb-4">
          <ButtonGradient
            text="LogIn"
            extraClassName="primary h5"
            loading={isLoading || loading}
          />
        </div>
      </form>

      <div className="text-center">
        Don't have an account yet?{" "}
        <Link to={"/Register"} className="text-decoration-none">
          <strong className="text-primary cursor-pointer">
            Create a free account
          </strong>
        </Link>
      </div>
    </div>
  );
};

export default Login;
