import React, { FC, useEffect, useState } from "react";
import styles from "./Password.module.scss";
import GenericInput from "../../../../Common/GenericInputs/GenericInputs";
import { ButtonGradient } from "../../../../Ui/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../../redux/store";
import {
  resetUserState,
  updatePasswordAsync,
} from "../../../../../redux/actions/userActions";
import { Alert } from "../../../../Ui/Alert/Alert";
import { isValidPassword } from "../../../../../utils/validation";

interface PasswordProps {}

const Password: FC<PasswordProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();

  const { loading, error, successMessage } = useSelector(
    (state: RootState) => state.user
  );

  const [formData, setFormData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const [errorTemp, setErrorTemp] = useState("");
  const [isShowInputWarning, setIsShowInputWarning] = useState<boolean>(false);

  useEffect(() => {
    dispatch(resetUserState());
  }, [dispatch]);

  useEffect(() => {
    if (error) setErrorTemp(error);
  }, [error]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorTemp("");
    // Check if newPassword and confirmNewPassword match
    if (formData.newPassword !== formData.confirmNewPassword) {
      setErrorTemp("New password do not match.");
      return;
    }

    if (!isValidPassword(formData.newPassword)) {
      setIsShowInputWarning(true);
      return;
    }

    dispatch(
      updatePasswordAsync({
        oldPassword: formData.oldPassword,
        newPassword: formData.newPassword,
      })
    );

    dispatch(resetUserState());
  };

  return (
    <div
      className={`  ${styles.Password} mw-100 d-flex justify-content-center`}
      data-testid="Password"
    >
      <form onSubmit={handleSubmit} className={`${styles.FormWidth} `}>
        <h2 className="mb-5 text-center">Change Password</h2>
        <div className="form-group mb-5">
          <GenericInput
            type="password"
            id="oldPassword"
            name="oldPassword"
            value={""}
            onChange={handleChange}
            placeholder="Old Password"
            labelText="Enter Old Password:"
            required={true}
            disabled={false}
          />
        </div>
        <div className="form-group mb-5">
          <GenericInput
            type="password"
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            placeholder="New Password"
            labelText="Enter New Password:"
            required={true}
            disabled={false}
            isShowInputWarning={isShowInputWarning}
          />
        </div>
        <div className="form-group mb-5">
          <GenericInput
            type="password"
            id="confirmNewPassword"
            name="confirmNewPassword"
            value={formData.confirmNewPassword}
            onChange={handleChange}
            placeholder="Confirm New Password"
            labelText="Confirm New Password:"
            required={true}
            disabled={false}
            isShowInputWarning={isShowInputWarning}
          />
        </div>

        {successMessage && (
          <div className="mb-2">
            <Alert Message={successMessage} alertType="success" />
          </div>
        )}

        {errorTemp && (
          <div className="mb-2">
            <Alert Message={errorTemp} alertType="warning" />
          </div>
        )}

        <div className="form-group">
          <ButtonGradient
            text="Change Password"
            extraClassName="primary h6"
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default Password;
