import { CurrencyCode } from "../api/backlinks";

export function formatCurrency(amount: number, currencyCode: CurrencyCode) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: 0,  // No decimals
      maximumFractionDigits: 0,  // No decimals
    }).format(amount);
  }
