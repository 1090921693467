import Cookies from "universal-cookie";

export const isTokenExpired = () => {
  const cookies = new Cookies();
  const token = cookies.get("TOKEN");

  if (!token) {
    window.location.href = "/";
    return null;
  } else {
    const tokenParts = token.split(".");
    const tokenDecoded = JSON.parse(atob(tokenParts[1]));
    const tokenExpiration = tokenDecoded.exp;
    const dateNow = new Date();

    if (tokenExpiration < dateNow.getTime() / 1000) {
      window.location.href = "/";
      return null;
    } else {
      return token;
    }
  }
};

export const isTokenMain = () => {
  const cookies = new Cookies();
  const token = cookies.get("TOKEN");

  if (token) {
    const tokenParts = token.split(".");
    const tokenDecoded = JSON.parse(atob(tokenParts[1]));
    const tokenExpiration = tokenDecoded.exp;
    const dateNow = new Date();

    if (tokenExpiration > dateNow.getTime() / 1000) {
      window.location.href = "/User-Profile";
    }
  }
};
