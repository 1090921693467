import React, { FC } from 'react';
import styles from './Counter.module.scss';

interface CounterProps {
  count: number; // Add the count prop
}

const Counter: FC<CounterProps> = ({ count }) => (
  <div className={styles.Counter} data-testid="Counter">
    <span>{count}</span>
  </div>
);

export default Counter;
