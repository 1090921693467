import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from "universal-cookie";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = apiUrl;

interface PrivateRouteProps {
  element: React.ReactElement;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const verifyAuthentication = async () => {
      try {
        const isAuthenticated = await checkAuthentication();
        setIsAuthenticated(isAuthenticated);

        if (!isAuthenticated) {
          navigate('/'); // Redirect to login if not authenticated
        }
      } catch (error) {
        console.error("An error occurred during authentication:", error);
        navigate('/'); // Redirect to login in case of an error
      }
    };

    verifyAuthentication();
  }, [navigate]);

  if (isAuthenticated === null){
    return null;
  }
  
  return element;
};

export const checkAuthentication = async () => {
  const token = new Cookies().get('TOKEN');

  if (!token) {
    return false;
  }

  const response = await fetch(`${BASE_URL}/auth-endpoint`, {
    method: 'GET',
    headers: { 'Authorization': `Bearer ${token}` },
  });

  return response.ok;
};
