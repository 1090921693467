import { FC, useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import styles from "./Favorites.module.scss";
import { RootState } from "../../../redux/store";
import { MenuItem } from "../../Lib/MantineTable/MantineTable";
import { IBacklink } from "../../../api/backlinks";
import { Trash3 } from "react-bootstrap-icons";
import { formatCurrency } from "../../../services/currency";
import { removeFromFavoritesAsync } from "../../../redux/actions/favoriteActions";
import { Dispatch } from "redux";
import { addToBagAsync } from "../../../redux/actions/bagActions";
import { FavoriteItem } from "../../../api/favorite";
import { DashBoardMainTitle } from "../../Ui/Title/Title";
import { DashboardBodyAlert } from "../../Ui/Alert/Alert";
import { ButtonDropDown } from "../../Ui/Button/Button";
import { fetchFavoritesAsync } from "../../../redux/actions/getFavoritesActions";
import { MantineProvider } from "@mantine/core";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
} from "mantine-react-table";
import { NumIcon } from "../../Ui/NumIcon/NumIcon";

interface FavoritesProps {}

const removeFromFavoritesMenuItem: MenuItem = {
  icon: Trash3,
  tooltipId: "cart-tooltip",
  text: "Add To Bag",
};

const Favorites: FC<FavoritesProps> = () => {
  const [favoriteItems, setFavoriteItems] = useState<IBacklink[]>([]);
  const [isActionAddBagPending, setIsActionAddBagPending] = useState(false);
  const [processingItemBuyId, setProcessingItemBuyId] = useState<string | null>(
    null
  );

  const dispatch = useDispatch<Dispatch<any>>();

  const { loadingFav, dataFav, errorFav } = useSelector(
    (state: RootState) => state.getFavorites
  );

  useEffect(() => {
    if (dataFav) {
      setFavoriteItems(dataFav);
    }
  }, [dataFav]);

  useEffect(() => {
    dispatch(fetchFavoritesAsync());
  }, [dispatch]);

  const debouncedAddToBag = _.debounce(async (item: IBacklink, contentType: string) => {
    setProcessingItemBuyId(null);
    setIsActionAddBagPending(false);

    const bagItem: FavoriteItem = {
      itemID: item._id,
      contentType: contentType,
    };

    dispatch(addToBagAsync(bagItem));
  }, 300);

  const debouncedhandleRemoveFromFavorites = _.debounce(
    async (item: IBacklink) => {
      dispatch(removeFromFavoritesAsync(item._id));
      setFavoriteItems((prev) => prev.filter((i) => i._id !== item._id));
    },
    300
  );

  const addBagButtonCell = useCallback(
    (info: IBacklink) => {
      // console.log("infoinfo", info);
      return (
        <span className="float-right">
          <ButtonDropDown
            text={"Buy"}
            loading={
              isActionAddBagPending &&
              processingItemBuyId === info._id.toString()
            }
            price={info.price}
            casinoPrice={info.casinoPrice}
            onClick={(contentType: string) => {
              setProcessingItemBuyId(info._id.toString());
              setIsActionAddBagPending(true);
              debouncedAddToBag(info, contentType);
            }}
          />
        </span>
      );
    },
    [debouncedAddToBag, isActionAddBagPending, processingItemBuyId]
  );

  const columnsMantine = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "removeFromOrder",
        header: "",
        Cell: (info: any) => (
          <span
            className="w-100 d-flex justify-content-left text-danger"
            role="button"
          >
            <removeFromFavoritesMenuItem.icon
              className="icon-md"
              onClick={() =>
                debouncedhandleRemoveFromFavorites(info.row.original)
              }
            />
          </span>
        ),
        size: 10,
      },
      {
        accessorKey: "website",
        header: "Website",
        Cell: ({ renderedCellValue, row }) => {

          return (
            <div>
              <a
                href={`https://www.${renderedCellValue?.toString()}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fw-bold text-primary cursor-pointer text-decoration-none"
              >
                {renderedCellValue}
              </a>
            </div>
          );
        },
      },
      {
        accessorKey: "dr",
        header: "DR",
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        mantineFilterRangeSliderProps: {
          max: 100, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 1,
        },
      },
      {
        accessorKey: "traffic",
        header: "Traffic",
        filterVariant: "range",
        filterFn: "betweenInclusive",
      },
      {
        accessorFn: (row) => row.siteType,
        header: "Site Allowing",
        Cell: ({ renderedCellValue, row }) => {
          if (typeof renderedCellValue === 'string' && renderedCellValue !== "-") {
            return (
              <div>
                {NumIcon({multipler: row.original.casinoPrice.priceMultiplier, siteType: renderedCellValue})}
              </div>
            );
          }

          return <div>{renderedCellValue}</div>;
        },
        filterFn: "contains",
        mantineFilterSelectProps: {
          data: ["Gambling"],
        },
        filterVariant: "select",
      },
      {
        accessorKey: "category",
        header: "Category",
      },
      {
        accessorKey: "country",
        header: "Country",
      },
      {
        accessorFn: (row) => row.price.amount,
        header: "Price",
        Cell: ({ renderedCellValue, row }) => {
          const { amount, currency } = row.original.price;

          return <div>{formatCurrency(amount, currency)}</div>;
        },
        filterVariant: "range-slider",
        filterFn: "betweenInclusive", // default (or between)
        mantineFilterRangeSliderProps: {
          max: 10_000, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 10,
        },
      },
    ],
    [debouncedhandleRemoveFromFavorites]
  );

  const table = useMantineReactTable({
    columns: columnsMantine,
    data: favoriteItems,
    enableStickyHeader: true,
    initialState: {
      showColumnFilters: true,
      sorting: [
        { id: "dr", desc: true }, //sort by state in ascending order by default
        { id: "traffic", desc: true }, //then sort by city in descending order by default
      ],
    },
    isMultiSortEvent: () => true,
    enableTopToolbar: true,
    enableRowActions: true,
    mantineTableContainerProps: {
      sx: { maxHeight: '79vh' }, //give the table a max height
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 50, //make actions column wider
        mantineTableBodyCellProps: {
          sx: {
            overflow: "visible",
          },
        },
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        overflow: "visible",
        fontSize: "15px !important",
        "@media (max-width: 800px)": {
          fontSize: "14px !important",
        },
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        overflow: "visible",
        fontSize: "15px !important",
        "@media (max-width: 800px)": {
          fontSize: "14px !important",
        },
      },
    },
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => <>{addBagButtonCell(row.original)}</>,
  });

  const renderBody = () => {
    if (loadingFav) {
      return <DashboardBodyAlert alertType={"loader"} />;
    }

    if (errorFav) {
      return (
        <DashboardBodyAlert
          Message={"Something went wrong! Please try again later."}
          alertType={"error"}
        />
      );
    }

    if (!favoriteItems.length) {
      return (
        <DashboardBodyAlert
          Message={"Your favorites is empty."}
          alertType={"empty"}
        />
      );
    }

    return (
      <div className="p-3">
        <div className="overflow-auto shadow">
          <MantineProvider
            theme={{
              colorScheme: "dark",
              primaryColor: "orange",
              primaryShade: 3,
            }}
          >
            <MantineReactTable table={table} />
          </MantineProvider>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.Favorites} data-testid="Favorites">
      <DashBoardMainTitle title="Favorites" />
      {renderBody()}
    </div>
  );
};

export default Favorites;
