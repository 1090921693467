import { FC, useEffect, useState } from "react";
import styles from "./Alert.module.scss";
import {
  XCircle,
  CheckCircle,
  ExclamationCircle,
  InfoCircle,
} from "react-bootstrap-icons";
import { TripleSpinner } from "../Spinner/Spinner";

interface AlertProps {
  Message: string | null;
  alertType: "danger" | "success" | "warning" | "info";
}

interface AlertDashBoardBody {
  Message?: string | null;
  alertType: "error" | "loader" | "empty";
}

// interface validationErrors {
//   error: string[];
// }

export const ERROR_MESSAGES_LOGIN = {
  loginError: "Invalid email or password. Please try again.",
  userNotFound: "User not found. Please register or try another email.",
  accountLocked:
    "Your account is locked due to multiple failed login attempts. Please try again later or reset your password.",
  unverifiedAccount:
    "Your account is not verified. Please verify your email address to login.",
  missingCredentials:
    "Email and password must be provided. Please check your inputs and try again.",
  serverError: "An unexpected error occurred. Please try again later.",
  maintenanceMode:
    "The system is currently under maintenance. Please try logging in later.",
  sessionExpired: "Your session has expired. Please login again.",
  passwordResetRequired: "You must reset your password before you can login.",
  twoFactorAuthRequired:
    "Two-factor authentication is required. Please check your authentication app for the latest code.",
};

export const Alert: FC<AlertProps> = ({ Message, alertType }) => {
  const [alertBoxClass, setAlertBoxClass] = useState("alert-danger");
  const [alertTextClass, setAlertTextClass] = useState("text-danger");

  useEffect(() => {
    switch (alertType) {
      case "danger":
        setAlertBoxClass("alert-danger");
        setAlertTextClass("text-danger");
        break;
      case "success":
        setAlertBoxClass("alert-success");
        setAlertTextClass("text-success");
        break;
      case "warning":
        setAlertBoxClass("alert-warning");
        setAlertTextClass("text-warning");
        break;
      case "info":
        setAlertBoxClass("alert-info");
        setAlertTextClass("text-info");
        break;
    }
  }, [alertType]);

  if (!Message) {
    return null;
  }

  return (
    <div className={styles.Error} data-testid="Error">
      <div
        className={`${styles.errorContainer} alert ${alertBoxClass} py-2 px-1`}
        role="alert"
      >
        <span className="d-inline-flex align-items-center text-black">
          <span
            className={`px-2 d-flex align-items-center ${alertTextClass} font-weight-bolder`}
          >
            {alertType === "danger" && <XCircle width={18} height={18} />}

            {alertType === "success" && <CheckCircle width={18} height={18} />}

            {alertType === "warning" && (
              <ExclamationCircle width={18} height={18} />
            )}

            {alertType === "info" && <InfoCircle width={18} height={18} />}
          </span>{" "}
          {Message || "Error"}{" "}
        </span>
      </div>
    </div>
  );
};

export const DashboardBodyAlert: FC<AlertDashBoardBody> = ({
  Message,
  alertType,
}) => {
  const errorAlert = () => {
    return (
      <div>
        <h4 className="mb-5 fw-bold d-block">{Message}</h4>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary d-block text-white float-center"
            onClick={() => window.location.reload()}
          >
            Retry
          </button>
        </div>
      </div>
    );
  };

  const emptyAlert = () => {
    return <h4 className="fw-bold">{Message}</h4>;
  };

  const loader = () => {
    return <TripleSpinner />;
  };

  return (
    <div className="w-100 vh-100 d-flex justify-content-center align-items-center">
      <div className="mb-5">
        {alertType === "error" && errorAlert()}
        {alertType === "empty" && emptyAlert()}
        {alertType === "loader" && loader()}
      </div>
    </div>
  );
};

