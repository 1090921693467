// src/redux/actions/registrationActions.ts
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { RegistrationData } from "../../interfaces/RegistrationData";
import { registerUserAPI } from "../../api/user"; // Implement this API function
import { ERROR_MESSAGES_LOGIN } from "../../components/Ui/Alert/Alert";

// Action Types
export enum RegistrationActionTypes {
  REGISTER_REQUEST = "REGISTER_REQUEST",
  REGISTER_SUCCESS = "REGISTER_SUCCESS",
  REGISTER_FAILURE = "REGISTER_FAILURE",
}

// Define action interfaces
interface RegisterRequestAction {
  type: RegistrationActionTypes.REGISTER_REQUEST;
}

interface RegisterSuccessAction {
  type: RegistrationActionTypes.REGISTER_SUCCESS;
  payload: {message: string};
}

interface RegisterFailureAction {
  type: RegistrationActionTypes.REGISTER_FAILURE;
  payload: string;
}

// Define a union type for all actions
export type RegistrationAction =
  | RegisterRequestAction
  | RegisterSuccessAction
  | RegisterFailureAction;

// Action Creators
const registerRequest = (): RegisterRequestAction => ({
  type: RegistrationActionTypes.REGISTER_REQUEST,
});

const registerSuccess = (data: {
  message: string;
}): RegisterSuccessAction => ({
  type: RegistrationActionTypes.REGISTER_SUCCESS,
  payload: data,
});

const registerFailure = (error: any): RegisterFailureAction => ({
  type: RegistrationActionTypes.REGISTER_FAILURE,
  payload: error,
});

// Async Action to Register User
export const registerUser =
  (
    userData: RegistrationData
  ): ThunkAction<void, RootState, unknown, RegistrationAction> =>
  async (dispatch) => {
    try {
      dispatch(registerRequest());
      // console.log('userData dispatch',userData);
      const response = await registerUserAPI(userData);
      if (response.success === false) {
        dispatch(registerFailure(response.error));
        return;
      }
      dispatch(registerSuccess({message: response.message}));
    } catch (error: any) {
      // Handle login error
      dispatch(registerFailure(ERROR_MESSAGES_LOGIN.serverError));
    }
  };
