import { ThunkAction } from "redux-thunk";
import { RootState } from "../store"; // Import the RootState type from your store
import { getAllFavorites } from "../../api/favorite"; // Import your API function
import { Dispatch } from "redux";
import { IBacklink } from "../../api/backlinks";

export const FETCH_FAVORITES_REQUEST = "FETCH_FAVORITES_REQUEST";
export const FETCH_FAVORITES_SUCCESS = "FETCH_FAVORITES_SUCCESS";
export const FETCH_FAVORITES_FAILURE = "FETCH_FAVORITES_FAILURE";

interface FetchFavoritesRequestAction {
  type: typeof FETCH_FAVORITES_REQUEST;
}

interface FetchFavoritesSuccessAction {
  type: typeof FETCH_FAVORITES_SUCCESS;
  payload: IBacklink[];
}

interface FetchFavoritesFailureAction {
  type: typeof FETCH_FAVORITES_FAILURE;
  error: string;
}

export type FetchFavoriteActionTypes =
  | FetchFavoritesRequestAction
  | FetchFavoritesSuccessAction
  | FetchFavoritesFailureAction;

export const fetchFavoritesAsync = (
): ThunkAction<void, RootState, unknown, FetchFavoriteActionTypes> => {
  return async (dispatch: Dispatch<FetchFavoriteActionTypes>) => {
    dispatch({ type: FETCH_FAVORITES_REQUEST });

    try {
      const favorites = await getAllFavorites(); // Pass the userId to fetch favorites
      dispatch({ type: FETCH_FAVORITES_SUCCESS, payload: favorites });
    } catch (error) {
      dispatch({
        type: FETCH_FAVORITES_FAILURE,
        error: "Failed to fetch favorites",
      });
    }
  };
};

export const UPDATE_FAVORITE_COUNT = "UPDATE_FAVORITE_COUNT";

export const updateFavoriteCount = (count: number) => ({
  type: UPDATE_FAVORITE_COUNT,
  payload: count,
});
