// src/redux/actions/orderActions.ts
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { Dispatch } from "redux";
import { IBacklink } from "../../api/backlinks";
import {
  IDetails,
  IOrderLink,
  addOrder,
  getAllOrderlinks,
  getAllOrderlinksAdmin,
  removeOrderlink,
  updateOrderlink,
  updateOrderlinkAdmin,
} from "../../api/order";
import { Types } from "mongoose";
import {
  SidebarCounterActionTypes,
  addToOrderLocal,
  removeFromOrderItemLocal,
} from "./sidebarActions";

// Action Types
export enum OrderActionTypes {
  ADD_TO_ORDER_REQUEST = "ADD_TO_ORDER_REQUEST",
  ADD_TO_ORDER_SUCCESS = "ADD_TO_ORDER_SUCCESS",
  ADD_TO_ORDER_FAILURE = "ADD_TO_ORDER_FAILURE",

  REMOVE_FROM_ORDER_REQUEST = "REMOVE_FROM_ORDER_REQUEST",
  REMOVE_FROM_ORDER_SUCCESS = "REMOVE_FROM_ORDER_SUCCESS",
  REMOVE_FROM_ORDER_FAILURE = "REMOVE_FROM_ORDER_FAILURE",

  GET_ORDER_REQUEST = "GET_ORDER_REQUEST",
  GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS",
  GET_ORDER_FAILURE = "GET_ORDER_FAILURE",
  CLEAN_ORDER = "CLEAN_ORDER",

  UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST",
  UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE",
  RESET_UPDATE_ORDER = "RESET_UPDATE_ORDER",

  GET_ORDER_ADMIN_REQUEST = "GET_ORDER_ADMIN_REQUEST",
  GET_ORDER_ADMIN_SUCCESS = "GET_ORDER_ADMIN_SUCCESS",
  GET_ORDER_ADMIN_FAILURE = "GET_ORDER_ADMIN_FAILURE",
  CLEAN_ORDER_ADMIN = "CLEAN_ORDER_ADMIN",

  UPDATE_ORDER_ADMIN_REQUEST = "UPDATE_ORDER_ADMIN_REQUEST",
  UPDATE_ORDER_ADMIN_SUCCESS = "UPDATE_ORDER_ADMIN_SUCCESS",
  UPDATE_ORDER_ADMIN_FAILURE = "UPDATE_ORDER_ADMIN_FAILURE",
  CLEAN_UPDATE_ORDER_ADMIN = "RESET_UPDATE_ORDER_ADMIN",

  REMOVE_ORDER_ADMIN_REQUEST = "REMOVE_ORDER_ADMIN_REQUEST",
  REMOVE_ORDER_ADMIN_SUCCESS = "REMOVE_ORDER_ADMIN_SUCCESS",
  REMOVE_ORDER_ADMIN_FAILURE = "REMOVE_ORDER_ADMIN_FAILURE",
  CLEAN_REMOVE_ORDER_ADMIN = "RESET_REMOVE_ORDER_ADMIN",
}

// Define action interfaces
interface AddToOrderRequestAction {
  type: OrderActionTypes.ADD_TO_ORDER_REQUEST;
}

interface AddToOrderSuccessAction {
  type: OrderActionTypes.ADD_TO_ORDER_SUCCESS;
  payload: string;
}

interface AddToOrderFailureAction {
  type: OrderActionTypes.ADD_TO_ORDER_FAILURE;
  error: string;
}

interface RemoveFromOrderRequestAction {
  type: OrderActionTypes.REMOVE_FROM_ORDER_REQUEST;
}

interface RemoveFromOrderSuccessAction {
  type: OrderActionTypes.REMOVE_FROM_ORDER_SUCCESS;
  payload: Types.ObjectId;
  resMessege: string;
}

interface RemoveFromOrderFailureAction {
  type: OrderActionTypes.REMOVE_FROM_ORDER_FAILURE;
  error: string;
}

interface GetOrderRequestAction {
  type: OrderActionTypes.GET_ORDER_REQUEST;
}

interface GetOrderSuccessAction {
  type: OrderActionTypes.GET_ORDER_SUCCESS;
  payload: IOrderLink[];
}

interface GetOrderFailureAction {
  type: OrderActionTypes.GET_ORDER_FAILURE;
  error: string;
}

interface CleanOrderAction {
  type: OrderActionTypes.CLEAN_ORDER;
}

interface UpdateOrderRequestAction {
  type: OrderActionTypes.UPDATE_ORDER_REQUEST;
}

interface UpdateOrderSuccessAction {
  type: OrderActionTypes.UPDATE_ORDER_SUCCESS;
  payload: string;
}

interface UpdateOrderFailureAction {
  type: OrderActionTypes.UPDATE_ORDER_FAILURE;
  error: string;
}

interface ResetUpdateOrderAction {
  type: OrderActionTypes.RESET_UPDATE_ORDER;
}

interface GetOrderAdminRequestAction {
  type: OrderActionTypes.GET_ORDER_ADMIN_REQUEST;
}

interface GetOrderAdminSuccessAction {
  type: OrderActionTypes.GET_ORDER_ADMIN_SUCCESS;
  payload: IOrderLink[];
}

interface GetOrderAdminFailureAction {
  type: OrderActionTypes.GET_ORDER_ADMIN_FAILURE;
  error: string;
}

interface CleanOrderAdminAction {
  type: OrderActionTypes.CLEAN_ORDER_ADMIN;
}

interface UpdateOrderAdminRequestAction {
  type: OrderActionTypes.UPDATE_ORDER_ADMIN_REQUEST;
}

interface UpdateOrderAdminSuccessAction {
  type: OrderActionTypes.UPDATE_ORDER_ADMIN_SUCCESS;
  payload: string;
}

interface UpdateOrderAdminFailureAction {
  type: OrderActionTypes.UPDATE_ORDER_ADMIN_FAILURE;
  error: string;
}

interface ResetUpdateOrderAdminAction {
  type: OrderActionTypes.CLEAN_UPDATE_ORDER_ADMIN;
}

interface RemoveOrderAdminRequestAction {
  type: OrderActionTypes.REMOVE_ORDER_ADMIN_REQUEST;
}

interface RemoveOrderAdminSuccessAction {
  type: OrderActionTypes.REMOVE_ORDER_ADMIN_SUCCESS;
  payload: Types.ObjectId;
  resMessege: string;
}

interface RemoveOrderAdminFailureAction {
  type: OrderActionTypes.REMOVE_ORDER_ADMIN_FAILURE;
  error: string;
}

interface CleanRemoveOrderAdminAction {
  type: OrderActionTypes.CLEAN_REMOVE_ORDER_ADMIN;
}

// Define a union type for all actions
export type OrderAction =
  | AddToOrderRequestAction
  | AddToOrderSuccessAction
  | AddToOrderFailureAction
  | RemoveFromOrderRequestAction
  | RemoveFromOrderSuccessAction
  | RemoveFromOrderFailureAction
  | GetOrderRequestAction
  | GetOrderSuccessAction
  | GetOrderFailureAction
  | CleanOrderAction
  | UpdateOrderRequestAction
  | UpdateOrderSuccessAction
  | UpdateOrderFailureAction
  | ResetUpdateOrderAction
  | GetOrderAdminRequestAction
  | GetOrderAdminSuccessAction
  | GetOrderAdminFailureAction
  | CleanOrderAdminAction
  | UpdateOrderAdminRequestAction
  | UpdateOrderAdminSuccessAction
  | UpdateOrderAdminFailureAction
  | ResetUpdateOrderAdminAction
  | RemoveOrderAdminRequestAction
  | RemoveOrderAdminSuccessAction
  | RemoveOrderAdminFailureAction
  | CleanRemoveOrderAdminAction;

// Add Order - response is a string
export const addToOrderAsync = (
  items: IBacklink[],
  orderID: string
): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction | SidebarCounterActionTypes
> => {
  return async (
    dispatch: Dispatch<OrderAction | SidebarCounterActionTypes>
  ) => {
    dispatch({ type: OrderActionTypes.ADD_TO_ORDER_REQUEST });
    try {
      const res: any = await addOrder(items, orderID);
      dispatch({
        type: OrderActionTypes.ADD_TO_ORDER_SUCCESS,
        payload: res.message,
      });
      dispatch(addToOrderLocal(items.length));
    } catch (error) {
      dispatch({
        type: OrderActionTypes.ADD_TO_ORDER_FAILURE,
        error: "Failed to add to order",
      });
    }
  };
};

// Remove Order - response is a string
export const removeFromOrderAsync = (
  orderID: Types.ObjectId
): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction | SidebarCounterActionTypes
> => {
  return async (
    dispatch: Dispatch<OrderAction | SidebarCounterActionTypes>
  ) => {
    dispatch({ type: OrderActionTypes.REMOVE_FROM_ORDER_REQUEST });

    try {
      const res = await removeOrderlink(orderID);
      dispatch({
        type: OrderActionTypes.REMOVE_FROM_ORDER_SUCCESS,
        payload: orderID,
        resMessege: res,
      });
      dispatch(removeFromOrderItemLocal());
      return res;
    } catch (error) {
      console.error("Failed to remove order:", error);
      dispatch({
        type: OrderActionTypes.REMOVE_FROM_ORDER_FAILURE,
        error: "Failed to remove order",
      });
    }
  };
};

// Get Order - response is an array of IOrderLink items
export const getOrderAsync = (): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction
> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.GET_ORDER_REQUEST });

    try {
      const items: IOrderLink[] = await getAllOrderlinks();
      dispatch({ type: OrderActionTypes.GET_ORDER_SUCCESS, payload: items });
    } catch (error) {
      dispatch({
        type: OrderActionTypes.GET_ORDER_FAILURE,
        error: "Failed to get orders",
      });
    }
  };
};

export const updateOrderAsync = (
  orderID: Types.ObjectId,
  details: IDetails
): ThunkAction<void, RootState, unknown, OrderAction> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.UPDATE_ORDER_REQUEST });
    try {
      const res: any = await updateOrderlink(orderID, details);

      dispatch({
        type: OrderActionTypes.UPDATE_ORDER_SUCCESS,
        payload: res.message,
      });
    } catch (error) {
      dispatch({
        type: OrderActionTypes.UPDATE_ORDER_FAILURE,
        error: "Failed to update order",
      });
    }
  };
};

export const cleanOrder = (): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction
> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.CLEAN_ORDER });
  };
};

export const resetUpdateOrder = (): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction
> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.RESET_UPDATE_ORDER });
  };
};

// Admin
export const getOrderAdminAsync = (): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction
> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.GET_ORDER_ADMIN_REQUEST });

    try {
      const items: IOrderLink[] = await getAllOrderlinksAdmin();
      dispatch({
        type: OrderActionTypes.GET_ORDER_ADMIN_SUCCESS,
        payload: items,
      });
    } catch (error) {
      dispatch({
        type: OrderActionTypes.GET_ORDER_ADMIN_FAILURE,
        error: "Failed to get orders",
      });
    }
  };
};

export const updateOrderAdminAsync = (
  IOrderLink: IOrderLink
): ThunkAction<void, RootState, unknown, OrderAction> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.UPDATE_ORDER_ADMIN_REQUEST });
    try {
      const res: any = await updateOrderlinkAdmin(IOrderLink);

      dispatch({
        type: OrderActionTypes.UPDATE_ORDER_ADMIN_SUCCESS,
        payload: res.message,
      });
    } catch (error) {
      dispatch({
        type: OrderActionTypes.UPDATE_ORDER_ADMIN_FAILURE,
        error: "Failed to update order",
      });
    }
  };
};

export const cleanOrderAdmin = (): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction
> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.CLEAN_ORDER_ADMIN });
  };
};

export const cleanUpdateOrderAdmin = (): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction
> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.CLEAN_UPDATE_ORDER_ADMIN });
  };
};

export const removeOrderAdminAsync = (
  orderID: Types.ObjectId
): ThunkAction<void, RootState, unknown, OrderAction> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.REMOVE_ORDER_ADMIN_REQUEST });
    try {
      const res: any = await removeOrderlink(orderID);

      dispatch({
        type: OrderActionTypes.REMOVE_ORDER_ADMIN_SUCCESS,
        payload: orderID,
        resMessege: res,
      });
    } catch (error) {
      dispatch({
        type: OrderActionTypes.REMOVE_ORDER_ADMIN_FAILURE,
        error: "Failed to remove order",
      });
    }
  };
};

export const cleanRemoveOrderAdmin = (): ThunkAction<
  void,
  RootState,
  unknown,
  OrderAction
> => {
  return async (dispatch: Dispatch<OrderAction>) => {
    dispatch({ type: OrderActionTypes.CLEAN_REMOVE_ORDER_ADMIN });
  };
};
