import axios from "axios";
import { Types } from "mongoose";
import { isTokenExpired } from "./auth";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = apiUrl;

export type CurrencyCode = "USD" | "EUR" | "GBP" | "JPY";

export interface IPrice {
  amount: number;
  currency: CurrencyCode;
}

export interface PriceData2 {
  price: IPrice;
  priceMultiplier: number | null;
}

export interface IBacklink extends Document {
  _id: Types.ObjectId;
  website: string;
  dr: number | string;
  traffic: number | string;
  category: string; // Array of strings for categories
  country: string;
  price: IPrice;
  casinoPrice?: PriceData2;
  isCasinoCheck?: boolean;
  siteType?: string;
  uniqueKey?: number;
}

export interface IBacklinkFaker {
  website: string;
  dr: number;
  siteType: string;
  traffic: number;
  category: string; // Array of strings for categories
  country: string;
  price: IPrice;
}

export const getAllBacklinks = async (): Promise<IBacklink[]> => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { 'Authorization': `Bearer ${token}` };

    const response = await axios.post(`${BASE_URL}/api/user/get-backlinks`, {}, { headers });

    return response.data;
  } catch (error) {
    throw error;
  }
};
