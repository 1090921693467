import { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import styles from "./General.module.scss";
import GenericInput from "../../../../Common/GenericInputs/GenericInputs";
import { ButtonGradient } from "../../../../Ui/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../../redux/store";
import { Alert } from "../../../../Ui/Alert/Alert";
import {
  getUserDataAsync,
  resetUserState,
  updateUserAsync,
} from "../../../../../redux/actions/userActions";
import { isValidEmail, isValidString } from "../../../../../utils/validation";

interface GeneralProps {}

const General: FC<GeneralProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { loading, error, successMessage, user } = useSelector(
    (state: RootState) => state.user
  );

  const [isShowInputWarning, setIsShowInputWarning] = useState<boolean>(false);
  const [formData, setFormData] = useState<{
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
  }>({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
  });

  useEffect(() => {
    dispatch(resetUserState());
    dispatch(getUserDataAsync());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        email: user.email || "",
        userName: user.username || "",
      });
    }
  }, [user]);

  const handleUpdateAccount = async (e: FormEvent) => {
    e.preventDefault();

    setIsShowInputWarning(false);
    if (
      !isValidString(formData.firstName) ||
      !isValidString(formData.lastName) ||
      !isValidEmail(formData.email)
    ) {
      setIsShowInputWarning(true);
      return;
    }

    dispatch(updateUserAsync(formData));
    dispatch(resetUserState());
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div
      className={`${styles.General} mw-100 d-flex justify-content-center`}
      data-testid="General"
    >
      <form
        onSubmit={handleUpdateAccount}
        action=""
        className={`${styles.FormWidth} `}
      >
        <h2 className="mb-5 text-center">Update User Data</h2>
        <div className="mb-5">
          <GenericInput
            type="text"
            id="username"
            name="username"
            value={formData.userName}
            placeholder="User Name"
            labelText="Enter Your User Name:"
            required={true}
            disabled={true}
          />
        </div>
        <div className="mb-5">
          <GenericInput
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            placeholder="First Name"
            labelText="Enter Your First Name:"
            required={true}
            isShowInputWarning={isShowInputWarning}
          />
        </div>
        <div className="mb-5">
          <GenericInput
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            placeholder="Last Name"
            labelText="Enter Your Last Name:"
            required={true}
            isShowInputWarning={isShowInputWarning}
          />
        </div>
        <div className="mb-5">
          <GenericInput
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            labelText="Enter Your Email Address:"
            required={true}
            isShowInputWarning={isShowInputWarning}
          />
        </div>

        {successMessage && (
          <div className="mb-2">
            <Alert Message={successMessage} alertType="success" />
          </div>
        )}

        {error && (
          <div className="mb-2">
            <Alert Message={error} alertType="danger" />
          </div>
        )}

        <div className="form-group ">
          <ButtonGradient
            text="Update User Data"
            extraClassName="primary h6"
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default General;
