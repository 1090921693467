import axios from "axios";
import { IBacklink, IPrice } from "./backlinks";
import { Types } from "mongoose";
import { isTokenExpired } from "./auth";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = apiUrl;

export interface IDetails {
  content?: string;
  anchor?: string;
  dateOfPublication?: Date | null;
  targetUrl?: string;
}

export interface IOrderLink {
  _id: Types.ObjectId;
  backlinkID: Types.ObjectId;
  userID: string;
  userName?: string;
  userEmail?: string;
  website: string;
  dr: number;
  traffic: number;
  category: string; // Array of strings for categories
  country: string;
  price: IPrice;
  details?: IDetails;
  isRemoved?: boolean;
  status?: string;
  discount?: number;
  purchaseDate?: Date; // Adding the purchaseDate field
  paymentMethod?: string;
  tax?: number;
  discountCode?: string;
  subRows?: IDetails[];
  contentType?: string;
}

export const getAllOrderlinks = async (): Promise<IOrderLink[]> => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/get-orderlinks`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllOrderlinksAdmin = async (): Promise<IOrderLink[]> => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/get-orders-admin`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOrderlinkAdmin = async (
  orderLink: IOrderLink
): Promise<string> => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/get-orders-admin`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// TODO: Add the correct type for the response
// TODO: ADD USER ID MONGOSE TYPE
export const addOrder = async (
  matchedItems: IBacklink[],
  orderID: string
): Promise<string> => {
  const orderObject = { matchedItems, orderID };
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/add-orderlink`,
      orderObject,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeOrderlink = async (
  orderLinkID: Types.ObjectId
): Promise<string> => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    if (!orderLinkID) throw new Error("No order ID provided");

    if (!Types.ObjectId.isValid(orderLinkID))
      throw new Error("Order ID must be a string");

    const request = { orderLinkID };

    const response = await axios.post(
      `${BASE_URL}/api/user/remove-order`,
      request,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOrderlink = async (
  orderLinkID: Types.ObjectId,
  details: IDetails
): Promise<string> => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { Authorization: `Bearer ${token}` };

    if (!orderLinkID) throw new Error("No order ID provided");

    if (!Types.ObjectId.isValid(orderLinkID))
      throw new Error("Order ID must be a string");

    const request = { orderLinkID, details };

    const response = await axios.post(
      `${BASE_URL}/api/user/update-order`,
      request,
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
