import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { Dispatch } from "redux";
import {
  resetPasswordAPI,
  updateUserAPI,
  updatePasswordAPI,
  deleteUserAPI,
  getUserDataAPI,
  passwordRestoreAPI,
  verifyNewAccountAPI,
} from "../../api/user";

// Action Types
export enum UserActionTypes {
  RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE",
  UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE",
  UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE",
  DELETE_USER_REQUEST = "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE = "DELETE_USER_FAILURE",
  GET_USER_DATA_REQUEST = "GET_USER_DATA_REQUEST",
  GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS",
  GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE",
  RESET_USER_STATE = "RESET_USER_STATE",
  PASSWORD_RESTORE_REQUEST = "PASSWPRD_RESTORE_REQUEST",
  PASSWORD_RESTORE_SUCCESS = "PASSWORD_RESTORE_SUCCESS",
  PASSWORD_RESTORE_FAILURE = "PASSWORD_RESTORE_FAILURE",
  VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILURE = "VERIFY_EMAIL_FAILURE",
}

// Action Interfaces
interface ResetPasswordRequestAction {
  type: UserActionTypes.RESET_PASSWORD_REQUEST;
}

interface ResetPasswordSuccessAction {
  type: UserActionTypes.RESET_PASSWORD_SUCCESS;
  payload: string;
}

interface ResetPasswordFailureAction {
  type: UserActionTypes.RESET_PASSWORD_FAILURE;
  error: string;
}

interface UpdateUserRequestAction {
  type: UserActionTypes.UPDATE_USER_REQUEST;
}

interface UpdateUserSuccessAction {
  type: UserActionTypes.UPDATE_USER_SUCCESS;
  payload: string;
}

interface UpdateUserFailureAction {
  type: UserActionTypes.UPDATE_USER_FAILURE;
  error: string;
}

interface UpdatePasswordRequestAction {
  type: UserActionTypes.UPDATE_PASSWORD_REQUEST;
}

interface UpdatePasswordSuccessAction {
  type: UserActionTypes.UPDATE_PASSWORD_SUCCESS;
  payload: string;
}

interface UpdatePasswordFailureAction {
  type: UserActionTypes.UPDATE_PASSWORD_FAILURE;
  error: string;
}

interface DeleteUserRequestAction {
  type: UserActionTypes.DELETE_USER_REQUEST;
}

interface DeleteUserSuccessAction {
  type: UserActionTypes.DELETE_USER_SUCCESS;
  payload: string;
}

interface DeleteUserFailureAction {
  type: UserActionTypes.DELETE_USER_FAILURE;
  error: string;
}

interface GetUserDataRequestAction {
  type: UserActionTypes.GET_USER_DATA_REQUEST;
}

interface GetUserDataSuccessAction {
  type: UserActionTypes.GET_USER_DATA_SUCCESS;
  payload: any;
}

interface GetUserDataFailureAction {
  type: UserActionTypes.GET_USER_DATA_FAILURE;
  error: string;
}

interface ResetUserStateAction {
  type: UserActionTypes.RESET_USER_STATE;
}

interface PasswordRestoreRequestAction {
  type: UserActionTypes.PASSWORD_RESTORE_REQUEST;
}

interface PasswordRestoreSuccessAction {
  type: UserActionTypes.PASSWORD_RESTORE_SUCCESS;
  payload: string;
} 

interface PasswordRestoreFailureAction {
  type: UserActionTypes.PASSWORD_RESTORE_FAILURE;
  error: string;
} 

interface VerifyEmailRequestAction {
  type: UserActionTypes.VERIFY_EMAIL_REQUEST;
}

interface VerifyEmailSuccessAction {
  type: UserActionTypes.VERIFY_EMAIL_SUCCESS;
  payload: string;
}

interface VerifyEmailFailureAction {
  type: UserActionTypes.VERIFY_EMAIL_FAILURE;
  error: string;
}

// Union Action Type
export type UserAction =
  | ResetPasswordRequestAction
  | ResetPasswordSuccessAction
  | ResetPasswordFailureAction
  | UpdateUserRequestAction
  | UpdateUserSuccessAction
  | UpdateUserFailureAction
  | UpdatePasswordRequestAction
  | UpdatePasswordSuccessAction
  | UpdatePasswordFailureAction
  | DeleteUserRequestAction
  | DeleteUserSuccessAction
  | DeleteUserFailureAction
  | GetUserDataRequestAction
  | GetUserDataSuccessAction
  | GetUserDataFailureAction
  | ResetUserStateAction
  | PasswordRestoreRequestAction
  | PasswordRestoreSuccessAction
  | PasswordRestoreFailureAction
  | VerifyEmailRequestAction
  | VerifyEmailSuccessAction
  | VerifyEmailFailureAction;

// Thunk Actions
export const resetPasswordAsync = (data: {
  token: string;
  newPassword: string;
}): ThunkAction<void, RootState, unknown, UserAction> => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({ type: UserActionTypes.RESET_PASSWORD_REQUEST });
    try {
      const response = await resetPasswordAPI(data);
      if (response.message) {
        dispatch({
          type: UserActionTypes.RESET_PASSWORD_SUCCESS,
          payload: response.message,
        });
      } else {
        dispatch({
          type: UserActionTypes.RESET_PASSWORD_FAILURE,
          error: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: UserActionTypes.RESET_PASSWORD_FAILURE,
        error: "Failed to reset password",
      });
    }
  };
};

export const updateUserAsync = (userData: {
  firstName: string;
  lastName: string;
  email: string;
}): ThunkAction<void, RootState, unknown, UserAction> => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({ type: UserActionTypes.UPDATE_USER_REQUEST });
    try {
      const response = await updateUserAPI(userData);

      if (response.message) {
        dispatch({
          type: UserActionTypes.UPDATE_USER_SUCCESS,
          payload: response.message,
        });
      } else {
        dispatch({
          type: UserActionTypes.UPDATE_USER_FAILURE,
          error: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: UserActionTypes.UPDATE_USER_FAILURE,
        error: "Failed to update user data",
      });
    }
  };
};

export const updatePasswordAsync = (passwordData: {
  oldPassword: string;
  newPassword: string;
}): ThunkAction<void, RootState, unknown, UserAction> => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({ type: UserActionTypes.UPDATE_PASSWORD_REQUEST });
    try {
      const response = await updatePasswordAPI(passwordData);
      if (response.message) {
        dispatch({
          type: UserActionTypes.UPDATE_PASSWORD_SUCCESS,
          payload: response.message,
        });
      } else {
        dispatch({
          type: UserActionTypes.UPDATE_PASSWORD_FAILURE,
          error: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: UserActionTypes.UPDATE_PASSWORD_FAILURE,
        error: "Failed to update password",
      });
    }
  };
};

export const deleteUserAsync = (deleteData: {
  password: string;
}): ThunkAction<void, RootState, unknown, UserAction> => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({ type: UserActionTypes.DELETE_USER_REQUEST });
    try {
      const response = await deleteUserAPI(deleteData);
      if (response.message) {
        dispatch({
          type: UserActionTypes.DELETE_USER_SUCCESS,
          payload: response.message,
        });
      } else {
        dispatch({
          type: UserActionTypes.DELETE_USER_FAILURE,
          error: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: UserActionTypes.DELETE_USER_FAILURE,
        error: "Failed to delete user",
      });
    }
  };
};

export const getUserDataAsync = (): ThunkAction<
  void,
  RootState,
  unknown,
  UserAction
> => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({ type: UserActionTypes.GET_USER_DATA_REQUEST });
    try {
      const response = await getUserDataAPI();

      if (response.data) {
        dispatch({
          type: UserActionTypes.GET_USER_DATA_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: UserActionTypes.GET_USER_DATA_FAILURE,
          error: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: UserActionTypes.GET_USER_DATA_FAILURE,
        error: "Failed to get user data",
      });
    }
  };
};

export const resetUserState = (): UserAction => {
  return {
    type: UserActionTypes.RESET_USER_STATE,
  };
};

export const passwordRestoreAsync = (email: string): ThunkAction<
  void,
  RootState,
  unknown,
  UserAction
> => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({ type: UserActionTypes.PASSWORD_RESTORE_REQUEST });
    try {
      const response = await passwordRestoreAPI(email);
      if (response.message) {
        dispatch({
          type: UserActionTypes.PASSWORD_RESTORE_SUCCESS,
          payload: response.message,
        });
      } else {
        dispatch({
          type: UserActionTypes.PASSWORD_RESTORE_FAILURE,
          error: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: UserActionTypes.PASSWORD_RESTORE_FAILURE,
        error: "Failed to restore password",
      });
    }
  };
}

export const verifyEmailAsync = (token: string): ThunkAction<
  void,
  RootState,
  unknown,
  UserAction
> => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({ type: UserActionTypes.VERIFY_EMAIL_REQUEST });
    try {
      const response = await verifyNewAccountAPI(token);
      if (response.message) {
        dispatch({
          type: UserActionTypes.VERIFY_EMAIL_SUCCESS,
          payload: response.message,
        });
      } else {
        dispatch({
          type: UserActionTypes.VERIFY_EMAIL_FAILURE,
          error: response.error,
        });
      }
    } catch (error) {
      dispatch({
        type: UserActionTypes.VERIFY_EMAIL_FAILURE,
        error: "Failed to verify email",
      });
    }
  };
}
