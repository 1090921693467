import { FC } from "react";
import Toast from "react-bootstrap/Toast";
import "./Toast.scss";

interface AutohideExampleProps {}

export const AutohideExample: FC<{
  message: string;
  removeToast: () => void;
  index: number;
  variant?: string;
}> = ({ message, removeToast, index, variant = "none" }) => {
  const style = {
    top: `${index * 6}rem`, // Adjust this value based on the height of your toasts
  };

  return (
    <div className="toast-container" style={style}>
      <div className="toast-wrapper">
        <Toast
          onClose={removeToast}
          bg={variant.toLowerCase()}
          show={true}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body className="text-white">{message}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};
