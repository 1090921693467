export const isValidPassword = (password: string) => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,60}$/.test(password);
};

export const isValidString = (string: string) => {
  return /^[a-zA-Z0-9_]{3,20}$/.test(string);
};

export const isValidTextAreaString = (string: string) => {
  return /^[a-zA-Z0-9_]{0,500}$/.test(string);
};

export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isEmpty = (string: string) => {
  return string.trim() === "";
};
