import { ThunkAction } from "redux-thunk";
import { RootState } from "../store"; // Import the RootState type from your store
import { getAllBacklinks } from "../../api/backlinks"; // Import your API function
import { Dispatch } from "redux";
import { IBacklink } from "../../api/backlinks";

export const FETCH_BACKLINKS_REQUEST = "FETCH_BACKLINKS_REQUEST";
export const FETCH_BACKLINKS_SUCCESS = "FETCH_BACKLINKS_SUCCESS";
export const FETCH_BACKLINKS_FAILURE = "FETCH_BACKLINKS_FAILURE";

interface FetchBacklinksRequestAction {
  type: typeof FETCH_BACKLINKS_REQUEST;
}

interface FetchBacklinksSuccessAction {
  type: typeof FETCH_BACKLINKS_SUCCESS;
  payload: IBacklink[];
}

interface FetchBacklinksFailureAction {
  type: typeof FETCH_BACKLINKS_FAILURE;
  error: string;
}

export type FetchBacklinksActionTypes =
  | FetchBacklinksRequestAction
  | FetchBacklinksSuccessAction
  | FetchBacklinksFailureAction;

export const fetchBacklinksAsync = (): ThunkAction<
  void,
  RootState,
  unknown,
  FetchBacklinksActionTypes
> => {
  return async (dispatch: Dispatch<FetchBacklinksActionTypes>) => {
    dispatch({ type: FETCH_BACKLINKS_REQUEST });

    try {
      const backlinkData = await getAllBacklinks();
      dispatch({ type: FETCH_BACKLINKS_SUCCESS, payload: backlinkData });
    } catch (error) {
      dispatch({
        type: FETCH_BACKLINKS_FAILURE,
        error: "Failed to fetch backlinks",
      });
    }
  };
};
