import { FC } from "react";
import styles from "./UserProfile.module.scss";
import SideBar from "./SideBar/SideBar";
import Products from "./Products/Products";
import { Outlet, Route, Routes } from "react-router-dom";
import Settings from "./Settings/Settings";
import Favorites from "./Favorites/Favorites";
import Bag from "./Bag/Bag";
import History from "./History/History";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import OrdersAdmin from "./Admin/Orders/Orders";

interface UserProfileProps {}

const UserProfile: FC<UserProfileProps> = () => {
  const { data } = useSelector((state: RootState) => state.sideBar);
  
  return (
    <div
      className={`${styles.UserProfile} vh-100 w-100 container-fluid p-relative`}
      data-testid="UserProfile"
    >
      {/* <div className={`${styles.FrameLayout}`}></div> */}

      <div className="row row-cols-2 ">
        <div className="col-auto px-0">
          <SideBar />
        </div>
        <div className={`col p-0 flex-none flex-auto ${styles.FadeIn}`}>
          {/* <Products /> */}
          <Routes>
            {/* Add a route for the Products component */}
            {/* <Route path="Home" element={<Settings />} /> */}
            <Route path="" element={<Products />} />
            {/* <Route path="Dashboard" element={<Dashboard />} /> */}
            <Route path="Settings/*" element={<Settings />} />

            <Route path="Bag" element={<Bag />} />
            <Route path="Favorites" element={<Favorites />} />
            <Route path="Orders" element={<History />} />

            {data.userRank === "admin" || data.userRank === "superAdmin" ? (
              <Route path="UsersOrders" element={<OrdersAdmin />} />
            ) : (
              ""
            )}

            {/* <Route path="Messages" element={<Messages />} /> */}
          </Routes>
          {/* The Outlet component is used to render nested routes */}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
