import { FC, useEffect, useState } from "react";
import styles from "./ResetPassword.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../redux/store";
import { isValidPassword } from "../../../utils/validation";
import {
  resetPasswordAsync,
  resetUserState,
} from "../../../redux/actions/userActions";
import { Link, useLocation } from "react-router-dom";
import { Title } from "../../Ui/Title/Title";
import GenericInput from "../../Common/GenericInputs/GenericInputs";
import { Alert } from "../../Ui/Alert/Alert";
import { ButtonGradient } from "../../Ui/Button/Button";

interface ResetPasswordProps {}

const ResetPassword: FC<ResetPasswordProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();

  const location = useLocation(); // Get the location object

  const { loading, error, resetPasswordMessage } = useSelector(
    (state: RootState) => state.user
  );
  const [errorTemp, setErrorTemp] = useState("");
  const [isShowInputWarning, setIsShowInputWarning] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    token: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  useEffect(() => {
    // Parse the search string to get the query parameters
    const queryParams = new URLSearchParams(location.search);
    // Get the token from the URL
    const token = queryParams.get("tokenReset");
    if (token) {
      setFormData((formData) => ({
        ...formData,
        token: token, // Set the token in state
      }));
    }
  }, [location]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorTemp("");
    // Check if newPassword and confirmNewPassword match
    if (formData.newPassword !== formData.confirmNewPassword) {
      setErrorTemp("New password do not match.");
      return;
    }

    if (!formData.token) {
      setErrorTemp("Token is missing.");
      return;
    }

    if (!isValidPassword(formData.newPassword)) {
      setIsShowInputWarning(true);
      return;
    }

    dispatch(
      resetPasswordAsync({
        token: formData.token,
        newPassword: formData.newPassword,
      })
    );

    dispatch(resetUserState());
  };

  return (
    <div className={`${styles.ResetPassword} `} data-testid="ResetPassword">
      <Title title="LinkWayz" />

      <form onSubmit={handleSubmit} className="row">
        <div className="form-group mb-4">
          <GenericInput
            type="password"
            id="newPassword"
            name="newPassword"
            value={formData.newPassword}
            onChange={handleChange}
            placeholder="New Password"
            labelText="Enter New Password:"
            required={true}
            disabled={false}
            isShowInputWarning={isShowInputWarning}
          />
        </div>

        <div className="form-group mb-5">
          <GenericInput
            type="password"
            id="confirmNewPassword"
            name="confirmNewPassword"
            value={formData.confirmNewPassword}
            onChange={handleChange}
            placeholder="Confirm New Password"
            labelText="Confirm New Password:"
            required={true}
            disabled={false}
            isShowInputWarning={isShowInputWarning}
          />
        </div>

        {resetPasswordMessage && (
          <div className="mb-2">
            <Alert Message={resetPasswordMessage} alertType="success" />
          </div>
        )}

        {error && (
          <div className="mb-2">
            <Alert Message={error} alertType="danger" />
          </div>
        )}

        {errorTemp && (
          <div className="mb-2">
            <Alert Message={errorTemp} alertType="warning" />
          </div>
        )}

        <div className="form-group mb-4">
          <ButtonGradient
            text="New Password"
            extraClassName="primary h5"
            loading={loading}
          />
        </div>
      </form>

      <div className="text-center">
        Back to:{" "}
        <Link to={"/Login"} className="text-decoration-none">
          <strong
            // onClick={onBackToLoginClick}
            className="text-primary cursor-pointer"
          >
            Login
          </strong>
        </Link>
      </div>
    </div>
  );
};

export default ResetPassword;
