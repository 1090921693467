import { FC } from "react";
import styles from "./Main.module.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  BackGroundAnimation,
  BackGroundLayout,
} from "../Ui/BackGround/BackGround";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { isTokenMain } from "../../api/auth";
import Login from "./Login/Login";
import Register from "./Register/Register";
import PasswordRestore from "./PasswordRestore/PasswordRestore";
import ResetPassword from "./ResetPassword/ResetPassword";

interface MainProps {}

const Main: FC<MainProps> = () => {
  isTokenMain();

  const location = useLocation();

  return (
    <div className={styles.Main} data-testid="Main">
      <div className="container-fluid vh-100 bg-black">
        <BackGroundAnimation />
        <span className="d-none d-xl-block">
          <BackGroundLayout />
        </span>

        <div className={`${styles.FrameLayout}`}></div>
        <div className="row h-100">
          <div className="col-6 d-none d-xl-block z-1 p-5">
            <div
              className={`${styles.Bg} w-100 h-100 p-4 d-flex align-items-center justify-content-center border-2 position-relative overflow-hidden`}
            >
              <div className={`${styles.mainBG} rounded shadow-1`}></div>
              <div className={`${styles.mainBGLayer}`}></div>
              <div className={`${styles.mainBGContent} position-relative`}>
                <h1 className="text-uppercase ">
                  <div className="d-flex justify-content-center align-items-center w-auto">
                  {/* <img
                    className={`${styles.Logo} mb-3`}
                    src={logo}
                    alt="Logo"
                  /> */}
                  </div>
                  <span className="text-white pt-2">
                    <span className="text-primary d-inline-block">We Link,</span> In many wayz!
                  </span>
                </h1>

                <hr className={`text-primary`} />
                <p className="text-white mb-1 text-center">
                  High-performance company that's built on providing top-notch
                  backlink services with an extreme focus on results and
                  conquering the search engines of Google.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-6 z-1 bg-transpert bg-lg-white ">
            <div className="form-container h-100 d-flex align-items-center justify-content-center position-relative">
              {/* <TransitionGroup key={location.pathname}> */}
              <TransitionGroup component={null}>
                <CSSTransition
                  key={location.pathname}
                  timeout={800}
                  classNames={{
                    enter: styles.fadeEnter,
                    enterActive: styles.fadeEnterActive,
                    exit: styles.fadeExit,
                    exitActive: styles.fadeExitActive,
                  }}
                >
                  <div className={`customScroll position-absolute py-3 px-3`}>
                    <Routes location={location}>
                      <Route path="/Login" element={<Login />} />
                      <Route path="/Register" element={<Register />} />
                      <Route
                        path="/Password-Restore"
                        element={<PasswordRestore />}
                      />
                      <Route
                        path="/Reset-Password"
                        element={<ResetPassword />}
                      />
                      <Route path="/" element={<Login />} />
                      {/* Redirect to /login if no exact match found */}
                    </Routes>
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Main;
