import { useCallback, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import styles from "./Products.module.scss";
import { MenuItem } from "../../Lib/MantineTable/MantineTable";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import {
  addToFavoritesAsync,
  removeFromFavoritesAsync,
} from "../../../redux/actions/favoriteActions";
import { FavoriteItem } from "../../../api/favorite";
import { addToBagAsync } from "../../../redux/actions/bagActions";
import { fetchBacklinksAsync } from "../../../redux/actions/backlinksActions";
import { IBacklink } from "../../../api/backlinks";
import { Star, StarFill, Dice6Fill } from "react-bootstrap-icons";
import { formatCurrency } from "../../../services/currency";
import { DashBoardMainTitle } from "../../Ui/Title/Title";
import { DashboardBodyAlert } from "../../Ui/Alert/Alert";
import { ButtonDropDown } from "../../Ui/Button/Button";
import { fetchFavoritesAsync } from "../../../redux/actions/getFavoritesActions";
import Spinner from "../../Ui/Spinner/Spinner";
import { MantineProvider } from "@mantine/core";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
  MRT_PaginationState,
} from "mantine-react-table";
import { NumIcon } from "../../Ui/NumIcon/NumIcon";

export interface Product {
  id: number;
  name: string;
  price: number;
  keywords: number;
  traffic: number;
  category: string;
}

const addFavoriteIcon: MenuItem = {
  icon: Star,
  tooltipId: "house-tooltip",
  text: "Add To Favorites",
};

const removeFavoriteIcon: MenuItem = {
  icon: StarFill,
  tooltipId: "house-tooltip",
  text: "Remove From Favorites",
};

export const gambling: MenuItem = {
  icon: Dice6Fill,
  tooltipId: "gambling-tooltip",
  text: "Remove From Favorites",
};

const Products = () => {
  const dispatch = useDispatch<Dispatch<any>>();

  const { isLoading, backLinks, error } = useSelector(
    (state: RootState) => state.backlinks
  );
  const { loadingFav, dataFav, errorFav } = useSelector(
    (state: RootState) => state.getFavorites
  );

  const [favoriteItems, setFavoriteItems] = useState<IBacklink[]>([]);
  const [backLinksData, setBackLinksData] = useState<IBacklink[]>([]);
  const [isActionPending, setIsActionPending] = useState(false);
  const [isActionAddBagPending, setIsActionAddBagPending] = useState(false);
  const [processingItemBuyId, setProcessingItemBuyId] = useState<string | null>(
    null
  );
  const [processingItemFavId, setProcessingItemFavId] = useState<string | null>(
    null
  );

  useEffect(() => {
    if (dataFav) {
      setFavoriteItems(dataFav);
    }
  }, [dataFav]);

  useEffect(() => {
    if (backLinks.length) {
      setBackLinksData(backLinks);
    }
  }, [backLinks]);

  useEffect(() => {
    if (!backLinks.length) dispatch(fetchBacklinksAsync());
  }, [dispatch, backLinks.length]);

  useEffect(() => {
    dispatch(fetchFavoritesAsync());
  }, [dispatch]);

  const debouncedAddToFavorites = _.debounce((item: IBacklink) => {
    setProcessingItemFavId(null);
    setIsActionPending(false);
    const favoriteItem: FavoriteItem = {
      itemID: item._id,
    };
    dispatch(addToFavoritesAsync(favoriteItem));
    setFavoriteItems((prevItems) => [...prevItems, item]);
  }, 300);

  const debouncedRemoveFromFavorites = _.debounce((item: IBacklink) => {
    setProcessingItemFavId(null);
    setIsActionPending(false);
    dispatch(removeFromFavoritesAsync(item._id));
    setFavoriteItems((prevItems) => [
      ...prevItems.filter((i) => i._id !== item._id),
    ]);
  }, 300);

  const debouncedAddToBag = _.debounce(
    async (item: IBacklink, contentType: string) => {
      setProcessingItemBuyId(null);
      setIsActionAddBagPending(false);

      const bagItem: FavoriteItem = {
        itemID: item._id,
        contentType: contentType,
      };

      dispatch(addToBagAsync(bagItem));
    },
    300
  );

  const createFavoriteCell = useCallback(
    (info: IBacklink) => {
      const isItemInFavorites = favoriteItems?.some(
        (item) => item._id === info._id
      );

      return (
        <span
          className="favoriteIcon d-flex justify-content-center text-primary cursor-pointer w-fit-content"
          role="button"
        >
          {isActionPending && processingItemFavId === info._id.toString() && (
            <Spinner color="gold" />
          )}

          {isItemInFavorites && processingItemFavId !== info._id.toString() && (
            <removeFavoriteIcon.icon
              className="icon-md"
              onClick={() => {
                setProcessingItemFavId(info._id.toString());
                setIsActionPending(true);
                debouncedRemoveFromFavorites(info);
              }}
            />
          )}
          {!isItemInFavorites &&
            processingItemFavId !== info._id.toString() && (
              <addFavoriteIcon.icon
                className="icon-md"
                onClick={() => {
                  setProcessingItemFavId(info._id.toString());
                  setIsActionPending(true);
                  debouncedAddToFavorites(info);
                }}
              />
            )}
        </span>
      );
    },
    [
      favoriteItems,
      debouncedRemoveFromFavorites,
      debouncedAddToFavorites,
      isActionPending,
      processingItemFavId,
    ] // dependency array, add other dependencies if any
  );

  const addBagButtonCell = useCallback(
    (info: IBacklink) => {
      // console.log("infoinfo", info);
      return (
        <span className="float-right">
          <ButtonDropDown
            text={"Buy"}
            loading={
              isActionAddBagPending &&
              processingItemBuyId === info._id.toString()
            }
            price={info.price}
            casinoPrice={info.casinoPrice}
            onClick={(contentType: string) => {
              setProcessingItemBuyId(info._id.toString());
              setIsActionAddBagPending(true);
              debouncedAddToBag(info, contentType);
            }}
          />
        </span>
      );
    },
    [debouncedAddToBag, isActionAddBagPending, processingItemBuyId]
  );

  const columnsMantine = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "addToFavorites",
        header: "",
        Cell: (info: any) => createFavoriteCell(info.row.original),
        size: 10,
      },
      {
        accessorKey: "website",
        header: "Website",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <div>
              <a
                href={`https://www.${renderedCellValue?.toString()}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fw-bold text-primary cursor-pointer text-decoration-none"
              >
                {renderedCellValue}
              </a>
            </div>
          );
        },
      },
      {
        accessorKey: "dr",
        header: "DR",
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        mantineFilterRangeSliderProps: {
          max: 100, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 1,
        },
      },
      {
        accessorKey: "traffic",
        header: "Traffic",
        filterVariant: "range",
        filterFn: "betweenInclusive",
      },
      {
        accessorFn: (row) => row.siteType,
        header: "Site Allowing",
        Cell: ({ renderedCellValue, row }) => {
          if (
            typeof renderedCellValue === "string" &&
            renderedCellValue !== "-"
          ) {
            return (
              <div>
                {NumIcon({
                  multipler: row.original.casinoPrice.priceMultiplier,
                  siteType: renderedCellValue,
                })}
              </div>
            );
          }

          return <div>{renderedCellValue}</div>;
        },
        filterFn: "contains",
        mantineFilterSelectProps: {
          data: ["Gambling"],
        },
        filterVariant: "select",
      },
      {
        accessorKey: "category",
        header: "Category",
      },
      {
        accessorKey: "country",
        header: "Country",
      },
      {
        accessorFn: (row) => row.price.amount,
        header: "Price",
        Cell: ({ renderedCellValue, row }) => {
          const { amount, currency } = row.original.price;

          return <div>{formatCurrency(amount, currency)}</div>;
        },
        filterVariant: "range-slider",
        filterFn: "betweenInclusive", // default (or between)
        // filterFn: "betweenInclusive", // default (or between)
        mantineFilterRangeSliderProps: {
          max: 10_000, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 10,
        },
      },
    ],
    [createFavoriteCell]
  );

  const table = useMantineReactTable({
    columns: columnsMantine,
    data: backLinksData,
    enableStickyHeader: true,
    initialState: {
      showColumnFilters: true,
      pagination: { pageIndex: 0, pageSize: 50 },
      sorting: [
        { id: "dr", desc: true }, //sort by state in ascending order by default
        { id: "traffic", desc: true }, //then sort by city in descending order by default
      ],
    },   
    mantineTableContainerProps: {
      sx: { maxHeight: "79vh" }, //give the table a max height
    },
    isMultiSortEvent: () => true,
    enableTopToolbar: true,
    enableRowActions: true,
    displayColumnDefOptions: {
      "mrt-row-actions": {
        header: "", //change header text
        size: 50, //make actions column wider
        mantineTableBodyCellProps: {
          sx: {
            overflow: "visible",
          },
        },
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        overflow: "visible",
        fontSize: "15px !important",
        "@media (max-width: 800px)": {
          fontSize: "14px !important",
        },
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        overflow: "visible",
        fontSize: "15px !important",
        "@media (max-width: 800px)": {
          fontSize: "14px !important",
        },
      },
    },
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => <>{addBagButtonCell(row.original)}</>,
  });

  const renderBody = () => {
    if (isLoading || loadingFav) {
      return <DashboardBodyAlert alertType={"loader"} />;
    }

    if (error || errorFav) {
      return (
        <DashboardBodyAlert
          Message={"Something went wrong! Please try again later."}
          alertType={"error"}
        />
      );
    }

    if (!backLinksData.length) {
      return (
        <DashboardBodyAlert
          Message={"No domains to show."}
          alertType={"empty"}
        />
      );
    }

    return (
      <div className="p-3">
        <div className="overflow-auto shadow">
          {/* <Example /> */}
          {/* <ProductTable backLinksData={backLinksData} columns={columns} /> */}
          <MantineProvider
            theme={{
              colorScheme: "dark",
              primaryColor: "orange",
              primaryShade: 3,
              //   colors: {
              //     blue: [
              //       //define 9 custom blue shades
              //     ],
              //   },
            }}
          >
            <MantineReactTable table={table} />
          </MantineProvider>
        </div>
      </div>
    );
  };

  return (
    <div className={`${styles.Favorites}`} data-testid="Favorites">
      <DashBoardMainTitle title="Domains" />
      {renderBody()}
    </div>
  );
};

export default Products;
