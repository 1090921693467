import axios from "axios";
import { isTokenExpired } from "./auth";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = apiUrl;

export const getSideBar = async (): Promise<any> => {
  try {
    const token = isTokenExpired();
    if (!token) {
      throw new Error("User is not authenticated");
    }
    const headers = { Authorization: `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/side-bar`,
      {},
      { headers }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};
