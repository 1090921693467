// favoriteActions.ts

import { ThunkAction } from "redux-thunk";
import { RootState } from "../store"; // Import the RootState type from your store
import {
  FavoriteItem,
  addToFavorites,
  removeFromFavorites,
} from "../../api/favorite"; // Import your API function
import {Types} from "mongoose";
import { addToFavoriteLocal, removeFromFavoritesLocal } from "./sidebarActions";

// Action Types
export const ADD_TO_FAVORITES_REQUEST = "ADD_TO_FAVORITES_REQUEST";
export const ADD_TO_FAVORITES_SUCCESS = "ADD_TO_FAVORITES_SUCCESS";
export const ADD_TO_FAVORITES_FAILURE = "ADD_TO_FAVORITES_FAILURE";
export const UPDATE_FAVORITE_ITEMS = "UPDATE_FAVORITE_ITEMS";
export const REMOVE_FROM_FAVORITES_REQUEST = "REMOVE_FROM_FAVORITES_REQUEST";
export const REMOVE_FROM_FAVORITES_SUCCESS = "REMOVE_FROM_FAVORITES_SUCCESS";
export const REMOVE_FROM_FAVORITES_FAILURE = "REMOVE_FROM_FAVORITES_FAILURE";

// Action Creators
interface RemoveFromFavoritesRequestAction {
  type: typeof REMOVE_FROM_FAVORITES_REQUEST;
}

interface RemoveFromFavoritesSuccessAction {
  type: typeof REMOVE_FROM_FAVORITES_SUCCESS;
  payload: any; // Define the payload type according to your API response
}

interface RemoveFromFavoritesFailureAction {
  type: typeof REMOVE_FROM_FAVORITES_FAILURE;
  error: string;
}

// Action Creators
interface AddToFavoritesRequestAction {
  type: typeof ADD_TO_FAVORITES_REQUEST;
}

interface AddToFavoritesSuccessAction {
  type: typeof ADD_TO_FAVORITES_SUCCESS;
  payload: any; // Define the payload type according to your API response
}

interface AddToFavoritesFailureAction {
  type: typeof ADD_TO_FAVORITES_FAILURE;
  error: string;
}

export type FavoriteActionTypes =
  | AddToFavoritesRequestAction
  | AddToFavoritesSuccessAction
  | AddToFavoritesFailureAction
  | RemoveFromFavoritesRequestAction
  | RemoveFromFavoritesSuccessAction
  | RemoveFromFavoritesFailureAction;

// Async Action - ADD Favorite
export const addToFavoritesAsync =
  (
    favoriteItem: FavoriteItem
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch({ type: ADD_TO_FAVORITES_REQUEST });

    try {
      const response = await addToFavorites(favoriteItem);
      dispatch({ type: ADD_TO_FAVORITES_SUCCESS, payload: response });
      dispatch(addToFavoriteLocal());
    } catch (error) {
      dispatch({
        type: ADD_TO_FAVORITES_FAILURE,
        error: "Failed to add to favorites",
      });
    }
  };

// Async Action - Remove Favorite
export const removeFromFavoritesAsync =
  (
    favoriteItemID: Types.ObjectId,
  ): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch({ type: REMOVE_FROM_FAVORITES_REQUEST });

    const favoriteItemObj: FavoriteItem = {
      itemID: favoriteItemID,
    };

    try {
      // Call your API function to remove the favorite
      // Replace 'removeFromFavorites' with the actual API function
      const response = await removeFromFavorites(favoriteItemObj);

      // Dispatch success action with the response data
      dispatch({ type: REMOVE_FROM_FAVORITES_SUCCESS, payload: response });
      dispatch(removeFromFavoritesLocal());
    } catch (error) {
      // Dispatch failure action with an error message
      dispatch({
        type: REMOVE_FROM_FAVORITES_FAILURE,
        error: "Failed to remove from favorites",
      });
    }
  };

export const UPDATE_FAVORITE_ITEMS_LOCAL = "UPDATE_FAVORITE_ITEMS_LOCAL";

export const favoriteItemsLocalReducer = (favorites: FavoriteItem[]) => ({
  type: UPDATE_FAVORITE_ITEMS_LOCAL,
  payload: favorites,
});
