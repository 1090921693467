import { FC, useState, useEffect, FormEvent, ChangeEvent } from "react";
import styles from "./DeleteAccount.module.scss";
import GenericInput from "../../../../Common/GenericInputs/GenericInputs";
import { ButtonGradient } from "../../../../Ui/Button/Button";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../../../../redux/store";
import {
  deleteUserAsync,
  resetUserState,
} from "../../../../../redux/actions/userActions";
import { Alert } from "../../../../Ui/Alert/Alert";
import { useNavigate } from "react-router-dom";

interface DeleteAccountProps {}

const DeleteAccount: FC<DeleteAccountProps> = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch<any>>();

  const cookies = new Cookies();
  const { loading, error, deleteMessage } = useSelector(
    (state: RootState) => state.user
  );

  useEffect(() => {
    if (deleteMessage) {
      cookies.remove("TOKEN", { path: "/" });
      window.location.href = "/";
    }
  }, [deleteMessage, navigate, cookies]);

  const [formData, setFormData] = useState<{
    password: string;
  }>({
    password: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleDeleteAccount = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(deleteUserAsync(formData));
    dispatch(resetUserState());
  };

  return (
    <div
      className={`${styles.DeleteAccount} mw-100 d-flex justify-content-center`}
      data-testid="DeleteAccount"
    >
      <form
        onSubmit={handleDeleteAccount}
        action=""
        className={`${styles.FormWidth}`}
      >
        <h2 className="mb-5 text-center">Delete Account</h2>

        <div className="form-group mb-5">
          <GenericInput
            type="password"
            id="password"
            name="password"
            value=""
            onChange={handleChange}
            placeholder="Enter your password to delete your account"
            labelText="Enter Your Password:"
            required={true}
            disabled={false}
          />
        </div>

        {error && (
          <div className="mb-2">
            <Alert Message={error} alertType="danger" />
          </div>
        )}

        <div className="form-group">
          <ButtonGradient
            text="Delete Account"
            extraClassName="danger h6"
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default DeleteAccount;
