import {
  FavoriteActionTypes,
  ADD_TO_FAVORITES_REQUEST,
  ADD_TO_FAVORITES_SUCCESS,
  ADD_TO_FAVORITES_FAILURE,
  REMOVE_FROM_FAVORITES_REQUEST,
  REMOVE_FROM_FAVORITES_SUCCESS,
  REMOVE_FROM_FAVORITES_FAILURE,
} from "../actions/favoriteActions";

// Define the initial state
export interface FavoriteState {
  loadingFav: boolean;
  dataFav: any; // Define the data type according to your API response
  errorFav: string | null;
}

const initialState: FavoriteState = {
  loadingFav: false,
  dataFav: null,
  errorFav: null,
};

export const favoriteReducer = (
  state = initialState,
  action: FavoriteActionTypes
): FavoriteState => {
  switch (action.type) {
    case ADD_TO_FAVORITES_REQUEST:
      return { ...state, loadingFav: true, errorFav: null };
    case ADD_TO_FAVORITES_SUCCESS:
      return { ...state, loadingFav: false, dataFav: action.payload, errorFav: null };
    case ADD_TO_FAVORITES_FAILURE:
      return { ...state, loadingFav: false, errorFav: action.error };
    default:
      return state;
  }
};

const initialRemoveFavoriteState: FavoriteState = {
  loadingFav: false,
  dataFav: null,
  errorFav: null,
};

export const removeFavoriteReducer = (
  state = initialRemoveFavoriteState,
  action: FavoriteActionTypes
): FavoriteState => {
  switch (action.type) {
    case REMOVE_FROM_FAVORITES_REQUEST:
      return { ...state, loadingFav: true, errorFav: null };
    case REMOVE_FROM_FAVORITES_SUCCESS:
      return { ...state, loadingFav: false, dataFav: action.payload, errorFav: null };
    case REMOVE_FROM_FAVORITES_FAILURE:
      return { ...state, loadingFav: false, errorFav: action.error };
    default:
      return state;
  }
};
