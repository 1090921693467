// src/redux/reducers/registrationReducer.ts
import {
  RegistrationAction,
  RegistrationActionTypes,
} from "../actions/registrationActions";

interface RegistrationState {
  isLoading: boolean;
  error: string | null;
  message: string | null;
}

const initialState: RegistrationState = {
  isLoading: false,
  error: null,
  message: null,
};

export const registrationReducer = (
  state = initialState,
  action: RegistrationAction
): RegistrationState => {
  switch (action.type) {
    case RegistrationActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case RegistrationActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: action.payload.message,
      };
    case RegistrationActionTypes.REGISTER_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
