import { IBacklink } from "../../api/backlinks";
import {
  FetchFavoriteActionTypes,
  FETCH_FAVORITES_REQUEST,
  FETCH_FAVORITES_SUCCESS,
  FETCH_FAVORITES_FAILURE,
  UPDATE_FAVORITE_COUNT,
} from "../actions/getFavoritesActions";

// Define the initial state for favorites
export interface GetAllFavoriteState {
  loadingFav: boolean;
  dataFav: IBacklink[]; // Define the data type according to your API response
  errorFav: string | null;
}

const initialState: GetAllFavoriteState = {
  loadingFav: false,
  dataFav: [],
  errorFav: null,
};

export const getFavoritesReducer = (
  state = initialState,
  action: FetchFavoriteActionTypes
): GetAllFavoriteState => {
  switch (action.type) {
    case FETCH_FAVORITES_REQUEST:
      return { ...state, loadingFav: true, errorFav: null };
    case FETCH_FAVORITES_SUCCESS:
      return { ...state, loadingFav: false, dataFav: action.payload, errorFav: null };
    case FETCH_FAVORITES_FAILURE:
      return { ...state, loadingFav: false, errorFav: action.error };
    default:
      return state;
  }
};

const initialStateCount = {
  number: 0,
};

export const favoriteCountReducer = (state = initialStateCount, action: any) => {
  switch (action.type) {
    case UPDATE_FAVORITE_COUNT:
      return action.payload;
    default:
      return state;
  }
};