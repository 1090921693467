import { FC, ChangeEvent, useState, useEffect } from "react";
import styles from "./GenericInputs.module.scss"; // Import your SCSS module
import { Alert } from "../../Ui/Alert/Alert";
import { Link } from "react-router-dom";
import {
  isEmpty,
  isValidEmail,
  isValidPassword,
  isValidString,
  isValidTextAreaString,
} from "../../../utils/validation";

interface GenericInputProps {
  type: string;
  id: string;
  name: string;
  value?: string;
  placeholder: string;
  labelText: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  required?: boolean;
  disabled?: boolean;
  error?: string | null; // Prop for error message
  rows?: number; // Prop for textarea rows
  ForgotPassword?: string | null;
  isShowInputWarning?: boolean;
}

const GenericInput: FC<GenericInputProps> = ({
  type,
  id,
  name,
  value,
  placeholder,
  labelText,
  onChange,
  required = false,
  disabled = false,
  error = null,
  rows = null,
  ForgotPassword = null,
  isShowInputWarning = false,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [inputError, setInputError] = useState<string | null>(error);
  const [inputLabel, setInputLabel] = useState<string | null>(labelText);

  useEffect(() => {
    setInputValue(value);
    setInputLabel(labelText);
  }, [value, labelText]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value: inputValue } = e.target;
    setInputValue(inputValue);
    setInputError(null); // Clear the previous error on input change

    if (required && isEmpty(inputValue)) {
      setInputError("This field is required");
    }

    if (type === "email" && required && !isValidEmail(inputValue)) {
      setInputError("Please enter a valid email address");
    }

    if (type === "password" && required && !isValidPassword(inputValue)) {
      setInputError(
        "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, and one number."
      );
    }

    if (type === "textarea" && required && !isValidTextAreaString(inputValue)) {
      setInputError(
        `${inputLabel} can only contain letters, numbers, and underscores .`
      );
    }

    if (type === "text" && required && !isValidString(inputValue)) {
      setInputError(
        `${inputLabel} can only contain letters, numbers, underscores and at least 3 characters long and at most 20.`
      );
    }

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={`form-group ${styles.GenericInputs}`}>
      <label htmlFor={id} className="form-control-label mb-2">
        {labelText}
      </label>

      {ForgotPassword?.length && (
        <Link to={ForgotPassword} className="text-decoration-none">
          <strong className="cursor-pointer float-right text-primary">
            Forgot Password?
          </strong>
        </Link>
      )}

      {type === "textarea" ? (
        <textarea
          id={id}
          name={name}
          value={inputValue || ""}
          onChange={handleChange}
          placeholder={placeholder}
          className={`form-control rounded shadow-sm ${styles.input}`}
          required={required}
          disabled={disabled}
          rows={rows || 3} // Default to 3 rows if not specified
        />
      ) : (
        <input
          type={type}
          id={id}
          name={name}
          value={inputValue || ""}
          onChange={handleChange}
          placeholder={placeholder}
          className={`form-control rounded shadow-sm ${styles.input}`}
          required={required}
          disabled={disabled}
        />
      )}
      {inputError && isShowInputWarning && (
        <div className="mt-2">
          <Alert Message={inputError} alertType="warning" />
        </div>
      )}
    </div>
  );
};

export default GenericInput;
