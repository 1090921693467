import { FC, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Dispatch } from "redux";
import styles from "./Bag.module.scss";
import { MenuItem } from "../../Lib/MantineTable/MantineTable";
import { Trash3, Check2Circle, XCircle } from "react-bootstrap-icons";
import { IBacklink } from "../../../api/backlinks";
import {
  cleanBag,
  fetchBagItemsAsync,
  removeFromBagAsync,
} from "../../../redux/actions/bagActions";
import { formatCurrency } from "../../../services/currency";
import PaymentBox from "../PaymentBox/PaymentBox";
import { DashBoardMainTitle } from "../../Ui/Title/Title";
import { DashboardBodyAlert } from "../../Ui/Alert/Alert";
import _ from "lodash";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { ButtonGradient } from "../../Ui/Button/Button";
import { cleanOrder } from "../../../redux/actions/orderActions";
import { cleanBagLocal } from "../../../redux/actions/sidebarActions";
import { MantineProvider } from "@mantine/core";
import {
  MantineReactTable,
  useMantineReactTable,
  MRT_ColumnDef,
} from "mantine-react-table";
import { NumIcon } from "../../Ui/NumIcon/NumIcon";

interface BagProps {}

const removeFavoriteIcon: MenuItem = {
  icon: Trash3,
  tooltipId: "house-tooltip",
  text: "Remove From Bag",
};

const Bag: FC<BagProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { dataBag, errorBag, loadingBag, discount } = useSelector(
    (state: RootState) => state.bag
  );
  const { resMessenge, addError } = useSelector(
    (state: RootState) => state.orders
  );

  const [modalShow, setModalShow] = useState(false);
  const [modalShowErr, setModalShowErr] = useState(false);
  const [bag, setBag] = useState<IBacklink[]>([]);
  const { isTableDisable } = useSelector((state: RootState) => state.bag);

  useEffect(() => {
    if (resMessenge?.length) {
      dispatch(cleanBag());
      dispatch(cleanOrder());
      dispatch(cleanBagLocal());
      setModalShow(true);
    }
  }, [resMessenge, dispatch]);

  useEffect(() => {
    if (addError?.length) {
      dispatch(cleanOrder());
      setModalShowErr(true);
    }
  }, [addError, dispatch]);

  useEffect(() => {
    if (dataBag) {
      // Adding a unique key to each item
      const dataWithKey = dataBag.map((item, index) => ({
        ...item,
        uniqueKey: index,
      }));
      setBag(dataWithKey);
    }
  }, [dataBag]);

  useEffect(() => {
    dispatch(fetchBagItemsAsync());
  }, [dispatch]);

  //TODO: ADD ACTION TYPE FOR ALL DISPACHES IN PROJECT

  const debouncedRemoveFromBag = _.debounce(async (uniqueKey: number) => {
    const itemToRemove = bag.find((item) => item.uniqueKey === uniqueKey);

    if (!itemToRemove) return;

    dispatch(removeFromBagAsync(itemToRemove));

    setBag((prev) => prev.filter((item) => item.uniqueKey !== uniqueKey));
  }, 300);

  function SuccessModal(props: any) {
    return (
      <Modal
        {...props}
        size="l"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="background-green-light">
          <div className="w-100 p-4 d-flex justify-content-center align-items-center text-white">
            <Check2Circle className="icon-xxl" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center py-4">
            <h3>Great!</h3>
            <p className="h5">Purchase successful! Thank you for your order.</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Go to orders</Button> */}
          <div className="d-flex justify-content-center w-100 p-2">
            <Link to={`/User-Profile/Orders`}>
              <ButtonGradient
                onClick={() => setModalShow(false)}
                text="Go to orders"
                loading={false}
                extraClassName="h6 primary"
              />
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  function ErrorModal(props: any) {
    return (
      <Modal
        {...props}
        size="l"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header className="bg-danger">
          <div className="w-100 p-4 d-flex justify-content-center align-items-center text-white">
            <XCircle className="icon-xxl" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center py-4">
            <h3>Oops!</h3>
            <p className="h5">
              Purchase failed. Please try again or contact support.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button onClick={props.onHide}>Go to orders</Button> */}
          <div className="d-flex justify-content-center w-100 p-2">
            <Link to={`/User-Profile/Bag`}>
              <ButtonGradient
                onClick={() => setModalShowErr(false)}
                text="Close"
                loading={false}
                extraClassName="h6 primary"
              />
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  const columnsMantine = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        id: "addToFavorites",
        header: "",
        Cell: (info: any) => (
          <span
            className="w-100 d-flex justify-content-left text-danger"
            role="button"
          >
            <removeFavoriteIcon.icon
              className="icon-md"
              onClick={() => {
                if (typeof info.row.original.uniqueKey !== "undefined") {
                  debouncedRemoveFromBag(info.row.original.uniqueKey);
                }
              }}
            />
          </span>
        ),
        size: 10,
      },
      {
        accessorKey: "website",
        header: "Website",
        Cell: ({ renderedCellValue, row }) => {
          return (
            <div>
              <a
                href={`https://www.${renderedCellValue?.toString()}`}
                target="_blank"
                rel="noopener noreferrer"
                className="fw-bold text-primary cursor-pointer text-decoration-none"
              >
                {renderedCellValue}
              </a>
            </div>
          );
        },
      },
      {
        accessorKey: "dr",
        header: "DR",
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        mantineFilterRangeSliderProps: {
          max: 100, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 1,
        },
      },
      {
        accessorKey: "traffic",
        header: "Traffic",
        filterVariant: "range",
        filterFn: "betweenInclusive",
      },
      {
        accessorFn: (row) => row.siteType,
        header: "Site Allowing",
        Cell: ({ renderedCellValue, row }) => {
          if (
            typeof renderedCellValue === "string" &&
            renderedCellValue !== "-"
          ) {
            return (
              <div>
                {NumIcon({
                  multipler: row.original.casinoPrice.priceMultiplier,
                  siteType: renderedCellValue,
                  contentType: row.original.contentType,
                })}
              </div>
            );
          }

          return <div>{renderedCellValue}</div>;
        },
        filterFn: "contains",
        mantineFilterSelectProps: {
          data: ["Gambling"],
        },
        filterVariant: "select",
      },
      {
        accessorKey: "category",
        header: "Category",
      },
      {
        accessorKey: "country",
        header: "Country",
      },
      {
        accessorFn: (row) => row.price.amount,
        header: "Price",
        Cell: ({ renderedCellValue, row }) => {
          const { amount, currency } = row.original.price;

          return <div>{formatCurrency(amount, currency)}</div>;
        },
        filterVariant: "range-slider",
        filterFn: "betweenInclusive", // default (or between)
        mantineFilterRangeSliderProps: {
          max: 10_000, //custom max (as opposed to faceted max)
          min: 0, //custom min (as opposed to faceted min)
          step: 10,
        },
      },
    ],
    [debouncedRemoveFromBag]
  );

  const table = useMantineReactTable({
    columns: columnsMantine,
    data: bag,
    enableStickyHeader: true,
    initialState: {
      showColumnFilters: true,
      sorting: [
        { id: "dr", desc: true }, //sort by state in ascending order by default
        { id: "traffic", desc: true }, //then sort by city in descending order by default
      ],
    },
    mantineTableContainerProps: {
      sx: { maxHeight: "79vh" }, //give the table a max height
    },
    isMultiSortEvent: () => true,
    enableTopToolbar: true,
    mantineTableHeadCellProps: {
      sx: {
        overflow: "visible",
        fontSize: "15px !important",
        "@media (max-width: 800px)": {
          fontSize: "14px !important",
        },
      },
    },
    mantineTableBodyCellProps: {
      sx: {
        overflow: "visible",
        fontSize: "15px !important",
        "@media (max-width: 800px)": {
          fontSize: "14px !important",
        },
      },
    },
  });

  const renderBody = () => {
    if (modalShow) {
      return (
        <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />
      );
    }

    if (modalShowErr) {
      return (
        <ErrorModal show={modalShowErr} onHide={() => setModalShowErr(false)} />
      );
    }

    if (loadingBag) {
      return <DashboardBodyAlert alertType={"loader"} />;
    }

    if (errorBag) {
      return (
        <DashboardBodyAlert
          Message={"Something went wrong! Please try again later."}
          alertType={"error"}
        />
      );
    }

    if (!bag.length) {
      return (
        <DashboardBodyAlert
          Message={"Your bag is empty."}
          alertType={"empty"}
        />
      );
    }

    return (
      <div className={styles.Bag} data-testid="Bag">
        <div className="row p-3">
          <div className="col-lg-8 col-6">
            <div className="overflow-auto shadow position-relative">
              <div className={`tableDisableLayout position-absolute w-100 h-100 ${isTableDisable ? "d-block" : "d-none"}`}></div>
              <MantineProvider
                theme={{
                  colorScheme: "dark",
                  primaryColor: "orange",
                  primaryShade: 3,
                }}
              >
                <MantineReactTable table={table} />
              </MantineProvider>
            </div>
          </div>
          <div className="col-lg-4 col-6 h-100 pl-0">
            <PaymentBox bagItems={bag} discount={discount} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.Bag} data-testid="Bag">
      <DashBoardMainTitle title="Bag" />
      {renderBody()}
    </div>
  );
};

export default Bag;
