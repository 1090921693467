import axios from "axios";
import {Types} from "mongoose";
import { isTokenExpired } from "./auth";

const apiUrl = process.env.REACT_APP_API_URL;
const BASE_URL = apiUrl;

export interface BagItem {
  _id?: Types.ObjectId;
  itemID: Types.ObjectId;
}

export const addToBag = async (bagItem: BagItem) => {
  try {
    const token = isTokenExpired();

    if (!token) {
      throw new Error("User is not authenticated");
    }

    const headers = { 'Authorization': `Bearer ${token}` };
    
    const response = await axios.post(`${BASE_URL}/api/user/add-bag`, bagItem, {
      headers,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getBag = async (): Promise<any> => {
  try {
    const token = isTokenExpired();
    if (!token) {
      throw new Error("User is not authenticated");
    }
    const headers = { 'Authorization': `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/get-bag`,
      {},
      { headers }
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const removeFromBag = async (itemID: Types.ObjectId) => {
  try {
    const token = isTokenExpired();
    if (!token) {
      throw new Error("User is not authenticated");
    }
    const headers = { 'Authorization': `Bearer ${token}` };

    const response = await axios.post(
      `${BASE_URL}/api/user/remove-bag`,
      { itemID: itemID},
      { headers }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
