import { ThunkAction } from "redux-thunk";
import { RootState } from "../store"; // Import the RootState type from your store
import { BagItem, addToBag, getBag } from "../../api/bag"; // Import your API function
import { Dispatch } from "redux";
import { removeFromBag } from "../../api/bag";
import { IBacklink } from "../../api/backlinks";
import {
  SidebarCounterActionTypes,
  removeFromBagItemLocal,
  addToBagLocal,
} from "./sidebarActions";

export enum BagActionTypes {
  ADD_TO_BAG_REQUEST = "ADD_TO_BAG_REQUEST",
  ADD_TO_BAG_SUCCESS = "ADD_TO_BAG_SUCCESS",
  ADD_TO_BAG_FAILURE = "ADD_TO_BAG_FAILURE",
  FETCH_BAG_ITEMS_REQUEST = "FETCH_BAG_ITEMS_REQUEST",
  FETCH_BAG_ITEMS_SUCCESS = "FETCH_BAG_ITEMS_SUCCESS",
  FETCH_BAG_ITEMS_FAILURE = "FETCH_BAG_ITEMS_FAILURE",
  UPDATE_BAG_COUNT = "UPDATE_BAG_COUNT",
  REMOVE_FROM_BAG_REQUEST = "REMOVE_FROM_BAG_REQUEST",
  REMOVE_FROM_BAG_SUCCESS = "REMOVE_FROM_BAG_SUCCESS",
  REMOVE_FROM_BAG_FAILURE = "REMOVE_FROM_BAG_FAILURE",
  CLEAN_BAG = "CLEAN_BAG",
  DISABLE_TABLE = "DISABLE_TABLE",
  ENABLE_TABLE = "ENABLE_TABLE",
}

interface BagState {
  bagData: IBacklink[];
  discount: number;
}

interface AddToBagRequestAction {
  type: typeof BagActionTypes.ADD_TO_BAG_REQUEST;
}

interface AddToBagSuccessAction {
  type: typeof BagActionTypes.ADD_TO_BAG_SUCCESS;
  payload: any; // Define the payload type according to your API response
}

interface AddToBagFailureAction {
  type: typeof BagActionTypes.ADD_TO_BAG_FAILURE;
  error: string;
}

export interface FetchBagItemsRequestAction {
  type: typeof BagActionTypes.FETCH_BAG_ITEMS_REQUEST;
}

export interface FetchBagItemsSuccessAction {
  type: typeof BagActionTypes.FETCH_BAG_ITEMS_SUCCESS;
  payload: BagState; // Define the payload type according to your API response
}

export interface FetchBagItemsFailureAction {
  type: typeof BagActionTypes.FETCH_BAG_ITEMS_FAILURE;
  error: string;
}

interface RemoveFromBagRequestAction {
  type: typeof BagActionTypes.REMOVE_FROM_BAG_REQUEST;
}

interface RemoveFromBagSuccessAction {
  type: typeof BagActionTypes.REMOVE_FROM_BAG_SUCCESS;
  payload: IBacklink; // Adjust the payload type as per your API response
}

interface RemoveFromBagFailureAction {
  type: typeof BagActionTypes.REMOVE_FROM_BAG_FAILURE;
  error: string;
}

interface CleanBagAction {
  type: typeof BagActionTypes.CLEAN_BAG;
}

interface DisableTableAction {
  type: typeof BagActionTypes.DISABLE_TABLE;
}

interface EnableTableAction {
  type: typeof BagActionTypes.ENABLE_TABLE;
}

export type BagAction =
  | AddToBagRequestAction
  | AddToBagSuccessAction
  | AddToBagFailureAction
  | FetchBagItemsRequestAction
  | FetchBagItemsSuccessAction
  | FetchBagItemsFailureAction
  | RemoveFromBagRequestAction
  | RemoveFromBagSuccessAction
  | RemoveFromBagFailureAction
  | CleanBagAction
  | DisableTableAction
  | EnableTableAction;

export const addToBagAsync =
  (bagItem: BagItem): ThunkAction<void, RootState, unknown, any> =>
  async (dispatch) => {
    dispatch({ type: BagActionTypes.ADD_TO_BAG_REQUEST });

    try {
      const response: IBacklink = await addToBag(bagItem);
      dispatch(addToBagLocal());
      dispatch({ type: BagActionTypes.ADD_TO_BAG_SUCCESS, payload: response });
    } catch (error) {
      dispatch({
        type: BagActionTypes.ADD_TO_BAG_FAILURE,
        error: "Failed to add to bag",
      });
    }
  };

export const fetchBagItemsAsync = (): ThunkAction<
  void,
  RootState,
  unknown,
  BagAction
> => {
  return async (dispatch: Dispatch<BagAction>) => {
    dispatch({ type: BagActionTypes.FETCH_BAG_ITEMS_REQUEST });

    try {
      const bagData = await getBag(); // Pass the userId to fetch favorites
      // console.log('bagData', bagData);
      dispatch({
        type: BagActionTypes.FETCH_BAG_ITEMS_SUCCESS,
        payload: bagData,
      });
    } catch (error) {
      dispatch({
        type: BagActionTypes.FETCH_BAG_ITEMS_FAILURE,
        error: "Failed to fetch bag items",
      });
    }
  };
};

export const removeFromBagAsync = (
  item: IBacklink
): ThunkAction<
  void,
  RootState,
  unknown,
  BagAction | SidebarCounterActionTypes
> => {
  return async (dispatch: Dispatch<BagAction | SidebarCounterActionTypes>) => {
    dispatch({ type: BagActionTypes.REMOVE_FROM_BAG_REQUEST });

    try {
      await removeFromBag(item._id);
      dispatch({
        type: BagActionTypes.REMOVE_FROM_BAG_SUCCESS,
        payload: item, // Dispatch the removed item as payload
      });
      dispatch(removeFromBagItemLocal());
    } catch (error) {
      dispatch({
        type: BagActionTypes.REMOVE_FROM_BAG_FAILURE,
        error: "Failed to remove item from bag",
      });
    }
  };
};

export const cleanBag = (): ThunkAction<
  void,
  RootState,
  unknown,
  BagAction
> => {
  return async (dispatch: Dispatch<BagAction>) => {
    dispatch({ type: BagActionTypes.CLEAN_BAG });
  };
};

export const disableTable = (): ThunkAction<
  void,
  RootState,
  unknown,
  BagAction
> => {
  return async (dispatch: Dispatch<BagAction>) => {
    dispatch({ type: BagActionTypes.DISABLE_TABLE });
  };
};

export const enableTable = (): ThunkAction<
  void,
  RootState,
  unknown,
  BagAction
> => {
  return async (dispatch: Dispatch<BagAction>) => {
    dispatch({ type: BagActionTypes.ENABLE_TABLE });
  };
};
