import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { Dispatch } from "redux";
import { getSideBar } from "../../api/sidebar";
import { ERROR_MESSAGES_LOGIN } from "../../components/Ui/Alert/Alert";

// Action types for counting items in the sidebar
export enum SideBarCounterTypes {
  SIDEBAR_COUNTER_REQUEST = "SIDEBAR_COUNTER_REQUEST",
  SIDEBAR_COUNTER_SUCCESS = "SIDEBAR_COUNTER_SUCCESS",
  SIDEBAR_COUNTER_FAILURE = "SIDEBAR_COUNTER_FAILURE",
  ADD_TO_FAVORITES = "ADD_TO_FAVORITES",
  REMOVE_FROM_FAVORITES = "REMOVE_FROM_FAVORITES",
  ADD_TO_BAG = "ADD_TO_BAG",
  REMOVE_FROM_BAG = "REMOVE_FROM_BAG",
  REMOVE_FROM_ORDER = "REMOVE_FROM_ORDER",
  ADD_TO_ORDER = "ADD_TO_ORDER",
  CLEAN_BAG = "CLEAN_BAG",
}

// Action interfaces
interface SidebarCounterRequestAction {
  type: typeof SideBarCounterTypes.SIDEBAR_COUNTER_REQUEST;
}

interface SidebarCounterSuccessAction {
  type: typeof SideBarCounterTypes.SIDEBAR_COUNTER_SUCCESS;
  payload: {
    data: {
      bagCount: number;
      favoriteCount: number;
      orderedCount: number;
      userRank: string;
    };
  };
}

interface SidebarCounterFailureAction {
  type: typeof SideBarCounterTypes.SIDEBAR_COUNTER_FAILURE;
  error: string;
}

interface AddToFavoriteAction {
  type: SideBarCounterTypes.ADD_TO_FAVORITES;
}

interface RemoveFromFavoritesAction {
  type: SideBarCounterTypes.REMOVE_FROM_FAVORITES;
}

interface AddToBagAction {
  type: SideBarCounterTypes.ADD_TO_BAG;
}

interface RemoveFromBagAction {
  type: SideBarCounterTypes.REMOVE_FROM_BAG;
}

interface AddToOrderAction {
  type: SideBarCounterTypes.ADD_TO_ORDER;
  payload: number; // The favorite item to remove
}

interface RemoveFromOrderAction {
  type: SideBarCounterTypes.REMOVE_FROM_ORDER;
}

interface CleanBagAction {
  type: SideBarCounterTypes.CLEAN_BAG;
}

// Union type for the actions
export type SidebarCounterActionTypes =
  | SidebarCounterRequestAction
  | SidebarCounterSuccessAction
  | SidebarCounterFailureAction
  | AddToFavoriteAction
  | RemoveFromFavoritesAction
  | AddToBagAction
  | RemoveFromBagAction
  | RemoveFromOrderAction
  | AddToOrderAction
  | CleanBagAction;

const sideBarCounterRequest = (): SidebarCounterRequestAction => ({
  type: SideBarCounterTypes.SIDEBAR_COUNTER_REQUEST,
});

const sideBarCounterSuccess = (data: {
  bagCount: number;
  favoriteCount: number;
  orderedCount: number;
  userRank: string;
}): SidebarCounterSuccessAction => ({
  type: SideBarCounterTypes.SIDEBAR_COUNTER_SUCCESS,
  payload: {
    data: data,
  },
});

const sideBarCounterFailure = (error: string): SidebarCounterFailureAction => ({
  type: SideBarCounterTypes.SIDEBAR_COUNTER_FAILURE,
  error: error,
});

export const fetchSideBarAsync = (): ThunkAction<
  void,
  RootState,
  unknown,
  SidebarCounterActionTypes
> => {
  return async (dispatch: Dispatch<SidebarCounterActionTypes>) => {
    try {
      dispatch(sideBarCounterRequest());

      try {
        const sideBar = await getSideBar(); // Pass the userId to fetch favorites
        dispatch(sideBarCounterSuccess(sideBar));
      } catch (error) {
        dispatch(sideBarCounterFailure("Failed to fetch SideBar"));
      }
    } catch (error: any) {
      // Handle login error
      dispatch(sideBarCounterFailure(ERROR_MESSAGES_LOGIN.serverError));
    }
  };
};

export const addToFavoriteLocal = (): AddToFavoriteAction => ({
  type: SideBarCounterTypes.ADD_TO_FAVORITES,
});

export const removeFromFavoritesLocal = (): RemoveFromFavoritesAction => ({
  type: SideBarCounterTypes.REMOVE_FROM_FAVORITES,
});

export const addToBagLocal = (): AddToBagAction => ({
  type: SideBarCounterTypes.ADD_TO_BAG,
});

export const removeFromBagItemLocal = (): RemoveFromBagAction => ({
  type: SideBarCounterTypes.REMOVE_FROM_BAG,
});

export const addToOrderLocal = (count: number): AddToOrderAction => ({
  type: SideBarCounterTypes.ADD_TO_ORDER,
  payload: count,
});

export const removeFromOrderItemLocal = (): RemoveFromOrderAction => ({
  type: SideBarCounterTypes.REMOVE_FROM_ORDER,
});

export const cleanBagLocal = (): CleanBagAction => ({
  type: SideBarCounterTypes.CLEAN_BAG,
});
