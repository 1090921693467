import { useEffect, FC, useMemo, ElementType } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  BagHeart,
  Star,
  BoxArrowLeft,
  Gear,
  BagCheck,
  Globe,
  Fire,
  PeopleFill
} from "react-bootstrap-icons";
import logo from "../../../assets/logo.webp";
import Counter from "../../Ui/Counter/Counter";
import styles from "./SideBar.module.scss";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import Cookies from "universal-cookie";
import { Link, To, useLocation } from "react-router-dom";
import { fetchSideBarAsync } from "../../../redux/actions/sidebarActions";

interface MenuItem {
  icon: ElementType; // The icon component
  tooltipId: string; // Tooltip ID
  text: string; // Menu item text
  count?: number; // Optional count property
  link?: To;
}

interface SideBarProps {}

const baseUrl = "/User-Profile";

const menu1Items: MenuItem[] = [
  // {
  //   icon: House,
  //   tooltipId: "house-tooltip",
  //   text: "Home",
  //   link: `${baseUrl}/Home`,
  // },
  {
    icon: Globe,
    tooltipId: "cart-tooltip",
    text: "Domains",
    link: `${baseUrl}`,
  },
  // {
  //   icon: ClipboardData,
  //   tooltipId: "clipboard-tooltip",
  //   text: "My Dashboard",
  //   link: `${baseUrl}/Dashboard`,
  // },
];

const menu3Items: MenuItem[] = [
  {
    icon: Gear,
    tooltipId: "settings-tooltip",
    text: "Account Settings",
    link: `${baseUrl}/Settings`,
  },
];

const menuItemLogout: MenuItem = {
  icon: BoxArrowLeft,
  tooltipId: "logout-tooltip",
  text: "Logout",
};

const Sidebar: FC<SideBarProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const cookies = new Cookies();
  const location = useLocation();
  const { data } = useSelector((state: RootState) => state.sideBar);

  // console.log(data);

  useEffect(() => {
    dispatch(fetchSideBarAsync());
  }, [dispatch]);

  // Function to check if a menu item is active
  const isNavItemActive = (path?: string) => {
    if (!path || path.toLocaleLowerCase() === baseUrl.toLocaleLowerCase()) {
      return location.pathname === baseUrl;
    }

    // Direct match or submenu navigation (e.g., /User-Profile/Settings/Password)
    const isDirectOrSubmenuMatch = location.pathname.startsWith(path);

    // Exclude default base URL selection if another menu item is matched
    if (path === baseUrl)
      return (
        isDirectOrSubmenuMatch &&
        !menu1Items
          .concat(menu2Items)
          .some(
            (item) =>
              item.link && location.pathname.startsWith(item.link.toString())
          )
      );

    return isDirectOrSubmenuMatch;
  };

  const menu2Items: MenuItem[] = useMemo(
    () => [
      {
        icon: Star,
        tooltipId: "star-tooltip",
        text: "My Favorites",
        count: data.favoriteCount | 0,
        link: `${baseUrl}/Favorites`,
      },
      {
        icon: BagHeart,
        tooltipId: "bagheart-tooltip",
        text: "My Bag",
        count: data.bagCount | 0,
        link: `${baseUrl}/Bag`,
      },
      {
        icon: BagCheck,
        tooltipId: "my-orders-tooltip",
        text: "My Orders",
        count: data.orderedCount | 0,
        link: `${baseUrl}/Orders`,
      },
      // {
      //   icon: Envelope,
      //   tooltipId: "envelope-tooltip",
      //   text: "Messages",
      //   count: 2,
      //   link: `${baseUrl}/Messages`,
      // },
    ],
    [data.favoriteCount, data.bagCount, data.orderedCount]
  );

  const menu4Items: MenuItem[] = useMemo(
    () => [
      {
        icon: Fire,
        tooltipId: "user-orders-tooltip",
        text: "User's Orders",
        link: `${baseUrl}/UsersOrders`,
      },
      {
        icon: PeopleFill,
        tooltipId: "user-list-tooltip",
        text: "User's List",
        link: `${baseUrl}/UsersList`,
      },
    ],
    []
  );

  const renderMenuItems = (menuItems: MenuItem[], menu: number) => {
    return menuItems.map((menuItem: MenuItem, index: number) => (
      <OverlayTrigger
        key={menuItem.tooltipId}
        placement="right"
        trigger={["hover", "focus"]}
        overlay={<Tooltip id={menuItem.tooltipId}>{menuItem.text}</Tooltip>}
      >
        <Link
          className={`${styles.menuItem} ${
            isNavItemActive(menuItem.link as string) ? styles.isSelected : ""
          }`}
          to={menuItem.link || "#"}
        >
          {menuItem.icon && <menuItem.icon className="icon-lg" />}
          {menuItem.count && menuItem.count > 0 ? (
            <Counter count={menuItem.count} />
          ) : null}
        </Link>
      </OverlayTrigger>
    ));
  };

  const logout = () => {
    cookies.remove("TOKEN", { path: "/" });
    window.location.href = "/";
  };

  const renderLogOut = () => {
    return (
      <OverlayTrigger
        key={menuItemLogout.tooltipId}
        placement="right"
        trigger={["hover", "focus"]}
        overlay={
          <Tooltip id={menuItemLogout.tooltipId}>{menuItemLogout.text}</Tooltip>
        }
      >
        <span className={styles.logout} onClick={() => logout()}>
          <menuItemLogout.icon className="icon-lg" />
        </span>
      </OverlayTrigger>
    );
  };

  return (
    <div className={`${styles.sidebar} shadow`}>
      <div className={styles.logo}>
        <a
          href="https://www.lwayz.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className={`${styles.Logo}`} src={logo} alt="Logo" />
        </a>
      </div>

      <div className={styles.menu}>{renderMenuItems(menu1Items, 1)}</div>

      <div className={styles.menu}>{renderMenuItems(menu2Items, 2)}</div>

      {data.userRank === "admin" || data.userRank === "superAdmin" ? (
        <div className={styles.menu}>{renderMenuItems(menu4Items, 1)}</div>
      ) : (
        ""
      )}

      <div className={styles.menu}>{renderMenuItems(menu3Items, 1)}</div>

      <div className={styles.logout}>{renderLogOut()}</div>
    </div>
  );
};

export default Sidebar;
