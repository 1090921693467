import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from "../components/Main/Main";
import NotFound from "../components/NotFound/NotFound";
import UserProfile from "../components/UserProfile/UserProfile";
import {PrivateRoute} from "./PrivateRoute";

const MyRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Main />} />
        <Route path="/User-Profile/*" element={<PrivateRoute element={<UserProfile />} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default MyRouter;
