import { IBacklink, IPrice } from "../../api/backlinks";
import { BagActionTypes, BagAction } from "../actions/bagActions";

// Add item to user's bag
interface BagState {
  loading: boolean;
  loadingBag: boolean;
  data: IBacklink | null;
  dataBag: IBacklink[];
  error: string | null;
  errorBag: string | null;
  removeLoading: boolean;
  removeData: IBacklink | null;
  removeError: string | null;
  sumPrice: IPrice;
  bagCount: number | null;
  discount: number;
  isTableDisable?: boolean;
}

const initialState: BagState = {
  loading: false,
  data: null,
  error: null,
  loadingBag: false,
  dataBag: [],
  errorBag: null,
  removeLoading: false,
  removeData: null,
  removeError: null,
  sumPrice: {
    amount: 0,
    currency: "USD",
  },
  bagCount: null,
  discount: 0,
  isTableDisable: false,
};

export const bagReducer = (
  state = initialState,
  action: BagAction
): BagState => {
  switch (action.type) {
    case BagActionTypes.ADD_TO_BAG_REQUEST:
      return { ...state, loading: true, error: null };
    case BagActionTypes.ADD_TO_BAG_SUCCESS:
      return { ...state, loading: false, data: action.payload, error: null };
    case BagActionTypes.ADD_TO_BAG_FAILURE:
      return { ...state, loading: false, error: action.error };

    case BagActionTypes.FETCH_BAG_ITEMS_REQUEST:
      return { ...state, loadingBag: true, errorBag: null };
    case BagActionTypes.FETCH_BAG_ITEMS_SUCCESS:
      // console.log('action.payload',action.payload)
      return {
        ...state,
        loadingBag: false,
        dataBag: action.payload.bagData,
        bagCount: action.payload.bagData.length,
        discount: action.payload.discount,
        sumPrice: {
          amount: action.payload.bagData.reduce(
            (acc, item) => acc + item.price.amount,
            0
          ),
          currency: "USD",
        },
        errorBag: null,
      };
    case BagActionTypes.FETCH_BAG_ITEMS_FAILURE:
      return { ...state, loadingBag: false, errorBag: action.error };

    case BagActionTypes.REMOVE_FROM_BAG_REQUEST:
      return { ...state, removeLoading: true, removeError: null };
    case BagActionTypes.REMOVE_FROM_BAG_SUCCESS:
      return {
        ...state,
        removeLoading: false,
        removeData: action.payload,
        bagCount: state.bagCount ? state.bagCount - 1 : 0,
        sumPrice: {
          ...state.sumPrice,
          amount: state.sumPrice.amount - action.payload.price.amount,
        },
        removeError: null,
      };
    case BagActionTypes.REMOVE_FROM_BAG_FAILURE:
      return { ...state, removeLoading: false, removeError: action.error };

    case BagActionTypes.CLEAN_BAG:
      return {
        ...state,
        loadingBag: false,
        dataBag: [],
        bagCount: 0,
        sumPrice: {
          amount: 0,
          currency: "USD",
        },
        errorBag: null,
      };
    case BagActionTypes.DISABLE_TABLE:
      return {
        ...state,
        isTableDisable: true,
      };
    case BagActionTypes.ENABLE_TABLE:
      return {
        ...state,
        isTableDisable: false,
      };
    default:
      return state;
  }
};
