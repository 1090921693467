//getAllBacklinks

import { IBacklink } from "../../api/backlinks";
import {
  FetchBacklinksActionTypes,
  FETCH_BACKLINKS_REQUEST,
  FETCH_BACKLINKS_SUCCESS,
  FETCH_BACKLINKS_FAILURE,
} from "../actions/backlinksActions";

//TODO: Define the initial state for favorites
interface BackLinksState {
  isLoading: boolean;
  error: string | null;
  backLinks: IBacklink[];
  favoriteIDs: string[];
  bagIDs: string[];
}

const initialState: BackLinksState = {
  isLoading: false,
  error: null,
  backLinks: [],
  favoriteIDs: [],
  bagIDs: [],
};

export const getBacklinksReducer = (
  state = initialState,
  action: FetchBacklinksActionTypes
): BackLinksState => {
  switch (action.type) {
    case FETCH_BACKLINKS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_BACKLINKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        backLinks: action.payload,
      };
    case FETCH_BACKLINKS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: "Failed to fetch data",
      };
    default:
      return state;
  }
};
