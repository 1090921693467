import { FC, useState } from "react";
import styles from "./PasswordRestore.module.scss";
import { Title } from "../../Ui/Title/Title";
import GenericInput from "../../Common/GenericInputs/GenericInputs";
import { ButtonGradient } from "../../Ui/Button/Button";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Dispatch } from "redux";
import { Alert } from "../../Ui/Alert/Alert";
import { passwordRestoreAsync } from "../../../redux/actions/userActions";

interface PasswordRestoreProps {
  // onBackToLoginClick: () => void;
  // Add any additional props you need for password restoration functionality
}

type FormData = {
  email: string;
};

const PasswordRestore: FC<PasswordRestoreProps> = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const { loading, error, resetPasswordMessage } = useSelector(
    (state: RootState) => state.user
  );

  const [formData, setFormData] = useState<FormData>({
    email: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    dispatch(passwordRestoreAsync(formData.email));
    // Add password restoration functionality here
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className={`${styles.Reset}`} data-testid="PasswordRestore">
      <Title title="LinkWayz" />
      {/* <h3 className="msg-info mb-3">Reset Your Password</h3> */}

      <form onSubmit={handleSubmit} className="row">

        <div className="form-group mb-5">
          <GenericInput
            type={"email"}
            id={"emailReset"}
            name={"email"}
            placeholder={"Enter your email"}
            labelText={"Email address:"}
            required={true}
            value={""}
            onChange={handleChange}
          />
        </div>

        {resetPasswordMessage && (
          <div className="mb-2">
            <Alert Message={resetPasswordMessage} alertType="success" />
          </div>
        )}

        {error && (
          <div className="mb-2">
            <Alert Message={error} alertType="danger" />
          </div>
        )}

        <div className="form-group mb-4">
          <ButtonGradient
            text="Send password reset link"
            extraClassName="primary h5"
            loading={loading}
          />
        </div>
      </form>

      <div className="text-center">
        Back to:{" "}
        <Link to={"/Login"} className="text-decoration-none">
          <strong
            // onClick={onBackToLoginClick}
            className="text-primary cursor-pointer"
          >
            Login
          </strong>
        </Link>
      </div>
    </div>
  );
};

export default PasswordRestore;
