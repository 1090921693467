import React, { FC } from "react";
import styles from "./Title.module.scss";
import logo from "../../../assets/logo.webp";

interface TitleProps {
  title: string;
}

export const Title: FC<TitleProps> = ({ title }) => (
  <div className={styles.Title} data-testid="Title">
    <div className="row justify-content-center mb-5">
      <img className={`${styles.Logo} mb-3`} src={logo} alt="Logo" />
      {/* <img className={`${styles.Logo} mb-3`} src={logo} alt="Logo" /> */}

      {/* <h2 className="text-center heading">
        We are <strong className="text-primary">{title}</strong>
      </h2> */}
    </div>
  </div>
);

export const DashBoardMainTitle: FC<TitleProps> = ({ title }) => (
  <div className={`m-0 h2 px-4 text-white shadow-sm ${styles.DashBoardMainTitle}`}>
    <h1 className="m-0 fw-bold">{title}</h1>
  </div>
);
