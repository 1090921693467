// src/redux/reducers/loginReducer.ts
import { LoginAction, LoginActionTypes } from "../actions/loginActions";

interface LoginState {
  isLoading: boolean;
  token: string | null;
  error: string | null;
}

const initialState: LoginState = {
  isLoading: false,
  token: null,
  error: null,
};

export const loginReducer = (
  state = initialState,
  action: LoginAction
): LoginState => {
  switch (action.type) {
    case LoginActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case LoginActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
      };
    case LoginActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
